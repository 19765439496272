export { DocsExport };

import { Utils } from '../lib/utils.js';
import { MatchSelectDialog, TournamentSelectDialog } from '../web_reports/dialogs.js';
import { webReport } from '../web_reports/report_class.js';
import { wrGraph } from './graphs.js';
import { StatPrep } from './stat_prep.js';
import { generateCSVReport } from './report_utils.js';

var DocsExport = {

	initGoogleDocsExportTab: function () {
		var wr = webReport;

		// init selectors
		$("#docs_export_selection_player select").prop("disabled", "").off('click');
		$("#docs_export_select_matches_btn").prop("disabled", "").click((e)=>{
			e.target.blur();
			MatchSelectDialog.openDialog(true);
		});
		$("#docs_export_select_tournaments_btn").prop("disabled", "").click((e)=>{
			e.target.blur();
			TournamentSelectDialog.openDialog(wr, true);
		});
		$("#docs_export_selection_mode input").prop("disabled", "");
		$("#docs_export_mode_tournament").prop("checked", false).change(this.changeExportMode);
		$("#docs_export_mode_match").prop("checked", true).change(this.changeExportMode);
		$("#SOLO_tab39 .match_mode_only").show();
		$("#SOLO_tab39 .tournament_mode_only").hide();
		if (wr.xteam[wr.currentTeam].tournaments.length === 0){
			$("#docs_export_mode_tournament").attr('disabled', 'disabled');
		}

		// init selected matches
		this.setGoogleDocsExportMatchesList();
		// init selected tournaments
		this.setGoogleDocsExportTournamentsList();
		// init selected player
		this.populateDocsExportPlayerSelector();

		// reset col 2+3
		$("#docs_export_button").html("Export&nbsp;to Google&nbsp;Docs");
		$("#docs_export_button").prop("disabled", "disabled");
		$(".docs_export_in_progress").removeClass("no_viz").addClass("no_viz");
		$("#docs_export_result_text_incomplete").show();
		$("#docs_export_result_text_complete").hide();
		$("#docs_export_complete_icon").hide();
		$("#docs_export_complete_icon a").prop("target", "").prop("href", "#");

		// attach Previous Exports dialog to link
		$("#docs_export_complete_list_link a").off("click").click($.proxy((e)=>{
			e.preventDefault();
			e.stopPropagation();
			this.openPreviousDocsExportsDialog();
		}, this));

		// init export button
		var that = this;
		var progressUpdater;
		$("#docs_export_button").off('click').click((e)=>{

			var updateDummyProgress = (clearMsg)=>{
				// Changes the update message every 15 seconds, until it gets to progress5, so the user
				// isn't just staring at an unchanging screen for ~1min.
				// Message is set in CSS for #docs_export_progress_spinner:before
				// progress1 = "Generating report..."
				// progress2 = "Collecting data..."
				// progress3 = "Uploading graphs..."
				// progress4 = "Still working..."
				// progress5 = "Cleaning up..."
				let spinnerDiv = $('#docs_export_progress_spinner');
				if (clearMsg || spinnerDiv.hasClass('no_viz')){
					spinnerDiv.removeClass('progress1 progress2 progress3 progress4 progress5');
				} else if (spinnerDiv.hasClass('progress1')){
					spinnerDiv.removeClass('progress1 progress2 progress3 progress4 progress5').addClass('progress2');
				} else if (spinnerDiv.hasClass('progress2')){
					spinnerDiv.removeClass('progress1 progress2 progress3 progress4 progress5').addClass('progress3');
				} else if (spinnerDiv.hasClass('progress3')){
					spinnerDiv.removeClass('progress1 progress2 progress3 progress4 progress5').addClass('progress4');
				} else if (spinnerDiv.hasClass('progress4')){
					spinnerDiv.removeClass('progress1 progress2 progress3 progress4 progress5').addClass('progress5');
				} else if (spinnerDiv.hasClass('progress5')){
					return;
				} else if (spinnerDiv.is(':visible')){
					spinnerDiv.removeClass('progress1 progress2 progress3 progress4').addClass('progress1');
				}
			};

			e.preventDefault();
			e.stopPropagation();
			e.target.blur();
			Utils.sendAnalyticsPage('/SOLO-REPORTS/docs-exporting');
			$("#docs_export_selection_mode input").prop("disabled", "disabled");
			$("#docs_export_selection_player select").prop("disabled", "disabled");
			$("#docs_export_select_matches_btn").prop("disabled", "disabled");
			$("#docs_export_button").html("Exporting...").prop("disabled", "disabled");
			$(".docs_export_in_progress").removeClass("no_viz block-tab-change").addClass("block-tab-change");
			$("#docs_export_result_text_incomplete").show();
			$("#docs_export_result_text_complete").hide();
			$("#docs_export_complete_icon").hide();
			$("#docs_export_complete_icon a").prop("target", "").prop("href", "#");
			updateDummyProgress();
			window.clearInterval(progressUpdater);
			progressUpdater = window.setInterval(updateDummyProgress, 15000);
			$("#docs_export_action")[0].scrollIntoView();

			return that.saveReportsToGoogleDoc().then((result)=>{
				window.clearInterval(progressUpdater);
				updateDummyProgress(true);
				Utils.sendAnalyticsPage('/SOLO-REPORTS/docs-exported');
				$("#docs_export_button").html("Export&nbsp;to Google&nbsp;Docs");
				$("#docs_export_complete_icon_text a").html(result["name"] + ".doc");
				$("#docs_export_complete_icon img").off("click").on("click", function(){
					window.open(result["webViewLink"], "_blank");
				});
				$("#docs_export_complete_icon a").prop("target", "_blank").prop("href", result["webViewLink"]);
				$(".docs_export_in_progress").removeClass("no_viz block-tab-change").addClass("no_viz");
				$("#docs_export_result_text_incomplete").hide();
				$("#docs_export_result_text_complete").show();
				$("#docs_export_complete_icon").show();
				$("#docs_export_complete_list_link").show();
				$("#docs_export_result")[0].scrollIntoView();
				$("#docs_export_selection_mode input").prop("disabled", "");
				$("#docs_export_selection_player select").prop("disabled", "");
				$("#docs_export_select_matches_btn").prop("disabled", "");
				window.setTimeout(()=>{ alert("Google Doc export complete!"); }, 100);
			}).catch((error)=>{
				window.clearInterval(progressUpdater);
				updateDummyProgress(true);
				$(".docs_export_in_progress").removeClass('no_viz block-tab-change').addClass('no_viz');
				$("#docs_export_button").html("Export&nbsp;to Google&nbsp;Docs").prop("disabled", "");
				$("#docs_export_selection_mode input").prop("disabled", "");
				$("#docs_export_selection_player select").prop("disabled", "");
				$("#docs_export_select_matches_btn").prop("disabled", "");
				let errmsg = error.message || error.statusText;
				alert(
					"Something went wrong. Please try again in a few minutes.\n " +
					"If the error persists, contact us for help.\n\n" +
					errmsg
				);
			});
		});

	},

	setGoogleDocsExportMatchesList: function () {
		$('#docs_export_selection_matches_list ul').empty();
		var wr = webReport;
		$.each(wr.selTrendMatches, function(i, m){
			let match = wr.xmatch[m];
			let li = $('<li/>').text(match.venue + ', ' + match.opponent);
			li.appendTo($('#docs_export_selection_matches_list ul'));
			if ((window.innerWidth <= 600 && i >= 4 && wr.selTrendMatches.length > 5) ||
				i >= 9 && wr.selTrendMatches.length > 10){
				// only show max 5 matches in vertical (mobile) layout, 10 in horizontal (web)
				li = $('<li/>').text('(+ ' + (wr.selTrendMatches.length - i - 1) + ' more)');
				li.appendTo($('#docs_export_selection_matches_list ul'));
				return false;
			}
		});
		if (wr.selTrendMatches.length === 0){
			let li = $('<li/>').text("No matches selected.");
			li.appendTo($('#docs_export_selection_matches_list ul'));
			$("#docs_export_button").attr("disabled", true);
		} else if ($('#docs_export_selection_player select').find(':selected').val()){
			$("#docs_export_button").attr("disabled", false);
		}
	},

	setGoogleDocsExportTournamentsList: function () {
		$('#docs_export_selection_tournaments_list ul').empty();
		var wr = webReport;
		var allTournaments = wr.xteam[wr.currentTeam].tournaments;
		$.each(wr.selTournaments, function(i, t){
			let tournament = allTournaments.find((o)=>{ return o.id === t; });
			let li = $('<li/>').text(tournament.name);
			li.appendTo($('#docs_export_selection_tournaments_list ul'));
			if (
				(window.innerWidth <= 600 && i >= 4 && allTournaments.length > 5) ||
				(i >= 9 && allTournaments.length > 10)
			){
				// only show max 5 tournaments in vertical (mobile) layout, 10 in horizontal (web)
				li = $('<li/>').text('(+ ' + (allTournaments.length - i - 1) + ' more)');
				li.appendTo($('#docs_export_selection_tournaments_list ul'));
				return false;
			}
		});
		if (wr.selTournaments.length === 0){
			let li = $('<li/>').text("No tournaments selected.");
			li.appendTo($('#docs_export_selection_tournaments_list ul'));
			$("#docs_export_button").attr("disabled", true);
		} else if ($('#docs_export_selection_player select').find(':selected').val()){
			$("#docs_export_button").attr("disabled", false);
		}
	},

	populateDocsExportPlayerSelector: function() {
		var wr = webReport;
		if (wr.currentTeam && wr.xteam[wr.currentTeam].players){
			var players = wr.xteam[wr.currentTeam].players;
			var selector = $('#docs_export_selection_player select');
			selector.find('option').remove().end().append($('<option />').val('').text('--------').prop('selected', true));
			$.each(players, function(){
				if (this.jersey){
					selector.append($('<option />').val(this.num).text(this.jersey + ' ' + this.name));
				} else {
					selector.append($('<option />').val(this.num).text(this.num + ' ' + this.name));
				}
			});
			selector.off('change').on('change', function(){
				if ($(this).find(':selected').val() && (
					($("input[name='export_mode']:checked").val() === 'match' && wr.selTrendMatches.length) ||
					($("input[name='export_mode']:checked").val() === 'tournament' && wr.selTournaments.length)
				)){
					$('#docs_export_button').attr('disabled', false);
				} else {
					$('#docs_export_button').attr('disabled', true);
				}
			});
		}
	},

	changeExportMode: function(evt) {
		var wr = webReport;
		if (evt.target.value === "match"){
			$("#SOLO_tab39 .tournament_mode_only").hide();
			$("#SOLO_tab39 b.match_mode_only").show();
			$("#SOLO_tab39 div.match_mode_only").fadeIn();
			if ($('#docs_export_selection_player select').find(':selected').val() && wr.selTrendMatches.length){
				$('#docs_export_button').attr('disabled', false);
			} else {
				$('#docs_export_button').attr('disabled', true);
			}
		} else if (evt.target.value === "tournament"){
			$("#SOLO_tab39 .match_mode_only").hide();
			$("#SOLO_tab39 b.tournament_mode_only").show();
			$("#SOLO_tab39 div.tournament_mode_only").fadeIn();
			if ($('#docs_export_selection_player select').find(':selected').val() && wr.selTournaments.length){
				$('#docs_export_button').attr('disabled', false);
			} else {
				$('#docs_export_button').attr('disabled', true);
			}
		}
	},

	saveReportsToGoogleDoc: async function() {
		function getSelPlayer() {
			var sel = $('#docs_export_selection_player select').find(':selected');
			if (!sel.val()){
				return null;
			} else {
				return {
					'num': sel.val(),
					'str': sel.text()
				};
			}
		}

		const categories = [
			'serve',
			'receive',
			'attack',
			'dig',
			'block',
			'fbp'
		];
		const reportTypes = {
			'match': [
				'summary_by_match',
				'match_comparison_by_player'
			],
			'tournament': [
				'summary_by_tournament',
				'tournament_comparison_by_player'
			],
		};

		var wr = webReport;
		var graph = wrGraph;
		var teamId = wr.currentTeam;
		var matches = wr.selTrendMatches;
		var selPlayer = getSelPlayer();
		if (selPlayer === null){
			let errtxt = 'No player selected.';
			return Promise.reject(new Error(errtxt));
		}
		var playerNum = selPlayer['num'];
		var playerStr = selPlayer['str'];
		var reportPackage = {};
		var data, selTournaments;
		var matchOrTournament = $("input[name='export_mode']:checked").val();
		if (matchOrTournament === 'tournament'){
			selTournaments = wr.selTournaments;
			matches = wr.getSelectedGamesAndMatchesByTournaments(selTournaments).matches;
			if (!matches.length){
				let errtxt = 'No matches in selected Tournament. Set up Tournaments on the MATCHES page -> Export, Input, and Editing.';
				return Promise.reject(new Error(errtxt));
			}
		}
		if (!teamId || !matches || !matches.length || !playerNum || !playerStr){
			let errtxt = 'Selection missing';
			return Promise.reject(new Error(errtxt));
		}

		var results, results2, settings, settings2, report, report2;
		try {
			if (matchOrTournament === 'match'){

				// Summary by Match
				results = StatPrep.processStatsIteration(wr.data, { type: 'match', list: matches }, { TODO: true } );
				settings  = {caption: "", hidegrid: false, footerrow : true, userDataOnFooter : false, hoverrows: false};
				report = generateCSVReport('summaryByMatch', results, [], settings, null, true);
				reportPackage['summary_by_match'] = report.csvOutput;

				// Match/Tournament Comparison by Player
				results2 = wr.assembleDataForMatchComparisonByPlayer(matches, playerNum);
				settings2  = {caption: "", hidegrid: false, footerrow : false, userDataOnFooter : false, hoverrows: false};
				report2 = generateCSVReport('matchComparisonByPlayer', results2, [], settings2, null, true);
				reportPackage['match_comparison_by_player'] = report2.csvOutput;

			} else if (matchOrTournament === 'tournament'){

				// Summary by Tournament
				results = wr.assembleDataForSummaryByTournament(selTournaments);
				settings  = {caption: "", hidegrid: false, footerrow : true, userDataOnFooter : false, hoverrows: false};
				report = generateCSVReport('summaryByTournament', results, [], settings, null, true);
				reportPackage['summary_by_tournament'] = report.csvOutput;

				// Tournament Comparison by Player
				results2 = wr.assembleDataForTournamentComparisonByPlayer(selTournaments, playerNum);
				settings2  = {caption: "", hidegrid: false, footerrow : false, userDataOnFooter : false, hoverrows: false};
				report2 = generateCSVReport('tournamentComparisonByPlayer', results2, [], settings2, null, true);
				reportPackage['tournament_comparison_by_player'] = report2.csvOutput;

			}

			data = new FormData();
			data.append('uid', wr.soloUid);
			data.append('team_id', teamId);
			data.append('player_str', playerStr);
			data.append('export_mode', matchOrTournament);
			data.append('report', JSON.stringify(reportPackage));
			var graphImgs, rt, cat;
			for (let i=0; i < reportTypes[matchOrTournament].length; i++){
				rt = reportTypes[matchOrTournament][i];
				// Save graph img to Google Drive
				// - will be inserted into template and deleted from drive on the server
				if (rt === 'summary_by_match' || rt === 'summary_by_tournament'){
					graphImgs = await graph.getImageBlobs(report.statsComplete, matchOrTournament).catch((error)=>{
						throw new Error(error);
					});
				} else if (rt === 'match_comparison_by_player' || rt === 'tournament_comparison_by_player'){
					graphImgs = await graph.getImageBlobs(report2.statsComplete, matchOrTournament).catch((error)=>{
						throw new Error(error);
					});
				}
				for (let j=0; j < categories.length; j++){
					cat = categories[j];
					data.append(('graphImg-' + rt + '-' + cat), graphImgs[rt][cat]);
				}
			}
		} catch (error){
			graph.closeGraphDialog();
			return Promise.reject(error);
		}

		return $.ajax({
			type     : "POST",
			url      : "/api/googledocs",
			data     : data,
			dataType : "json",
			contentType: false,
			processData: false,
			cache: false
		}).fail(function() {
			window.alert("Google Docs data could not be saved due to a server error.");
		});

	},

	openPreviousDocsExportsDialog: function(){
		var wr = webReport;
		var that = this;
		var height = 550;
		var width = 499;
		if (window.innerWidth < 499){
			width = window.innerWidth;
		}
		var $dlg = $("#previous-docs-exports-dialog").dialog({
			height: height,
			width: width,
			modal: true,
			resizable: false,
			autoOpen: false,
			title: "Google Docs Exports",
			open: async function(){
				$("#previous-docs-exports-dialog-team-name").text(wr.xteam[wr.currentTeam].name);
				$("body").css("overflow", "hidden");
				$("#previous-docs-exports-dialog-list").empty().append(
					$("<div id='previous-docs-exports-dialog-list-progress'><i class='fa fa-spinner fa-spin fa-3x'></i></div>")
				);
				$("#close-previous-docs-exports-dialog-btn").off("click").on("click", function(){
					$("#previous-docs-exports-dialog").dialog("close");
				});
				var docRowHtml = "<div class='previous-docs-export-row'><span /></div>";
				var docRow, filename;
				return await that.fetchPreviousDocsExports().then((docs)=>{
					for (let i=0; i < docs.length; i++){
						docRow = $(docRowHtml);
						let dt = new Date(parseInt(docs[i]['properties']['generatedDate']));
						let mt = docs[i]['properties'].matchOrTournament;
						docRow.find('span').text(
							dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString() + ' - ' +
							docs[i]['properties'].player + ' - ' +
							(mt.charAt(0).toUpperCase() + mt.slice(1))
						);
						/* jshint -W083 */
						docRow.click(function(){
							// open doc in new window
							window.open(docs[i]["webViewLink"], "_blank");
						});
						/* jshint +W083 */

						docRow.find("span").html(filename);
						$("#previous-docs-exports-dialog-list").append(docRow);
					}
					if (docs.length === 0){
						docRow = $(docRowHtml);
						docRow.addClass('no-results').find('span').text("(No results found.)");
						$("#previous-docs-exports-dialog-list").append(docRow);
					}
				}).catch(()=>{
					$("body").css("overflow", "auto");
					$(this).dialog("close");
					window.alert("Could not retrieve list of Docs, please try again in a moment.");
				}).finally(()=>{
					$("#previous-docs-exports-dialog-list-progress").remove();
				});
			},
			close: function(){
				$("body").css("overflow", "auto");
				$(this).dialog("close");
			}
		});
		$dlg.dialog("open");
	},

	fetchPreviousDocsExports: async function(){
		var wr = webReport;
		return $.ajax({
			type     : "GET",
			url      : "/api/googledocs/" + wr.soloUid + "/" + wr.currentTeam,
			dataType : "json",
		});
	}

};