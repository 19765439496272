
export {
	playersObject,
	isDemoAccount,
	generateStatsTableRows,
	generateCSVReport,
	publishReport,
	publishReportForBoxScoresTabForTeam,
	publishChart,
	getColumnsFromTemplate,
	post_to_url,
	translateRotation,
	clearStatsConfigForLargeReport,
	setResizeHandler,
	init,
};

import _ from 'lodash';
import 'bootstrap';
import 'jquery-ui-dist/jquery-ui'; // this must come after bootstrap
import 'jquery-templates';
import 'jqGrid/js/i18n/grid.locale-en.js';
import 'jqGrid/js/jquery.jqGrid';
import 'updated-jqplot/dist/jquery.updated-jqplot';
import 'updated-jqplot/dist/plugins/jqplot.canvasTextRenderer.js';
import 'updated-jqplot/dist/plugins/jqplot.canvasAxisTickRenderer.js';
import 'updated-jqplot/dist/plugins/jqplot.barRenderer.js';
import 'updated-jqplot/dist/plugins/jqplot.categoryAxisRenderer.js';
import 'updated-jqplot/dist/plugins/jqplot.dateAxisRenderer.js';
import 'updated-jqplot/dist/plugins/jqplot.canvasOverlay.js';

import '../lib/jquery-extensions.js';

import { CONFIG } from './config.js';
import { App } from './app.js';
import { StatPrep } from './stat_prep.js';
import { Stats } from './stats.js';
import { wrGraph } from './graphs.js';

// since we can't dynamically import from web_reports OR touch_reports,
// we'll need these to be passed in.
let webReport, Columns, ReportConfig;
var init = function (opts) {
	if (opts && opts.webReport) {
		webReport = opts.webReport;
	}
	if (opts && opts.Columns) {
		Columns = opts.Columns;
	}
	if (opts && opts.ReportConfig) {
		ReportConfig = opts.ReportConfig;
	}
};

var CODE2ENGLISH1 = {
	'aces_per_game'           : "Aces/Game",
	'assists_per_game'        : "Assists/Game",
	'combined_kills_per_game' : "Kills/Game",
	'blocks_per_game'         : "Blocks/Game",
	'pt_score_percent'        : "Point Scoring&nbsp;%",
	'side_out_percent'        : "Side Out&nbsp;%"
};

var isDemoAccount = function (uid) {
	if (uid === "DEMO" || uid === "2016olympics") {
		return true;
	} else {
		return false;
	}
};

var getCodeTranslation = function (code) {

	if (CODE2ENGLISH1[code]) {
		return CODE2ENGLISH1[code];
	} else {
		return code;
	}

};

var allStatsCSVForPointLogs = '';

var generateStatsTableRows = function (data, columns, opts, opponentName) {

	var statRows = [];

	if (!opts) {
		opts = {};
	}

	// stats are dependent on knowing whether or not the recorder is
	// using in_rally stats.  we can find this from data.agg
	var inRally = webReport.buildInRallyBoolean(data);

	var who = (opts.who) ? opts.who : 'us';
	var blockAssistsHalfValue = (opts.blockAssistsHalfValue) ? opts.blockAssistsHalfValue : false;

	var statsComplete1 = Stats.calc(data.byRow, columns, { blockAssistsHalfValue: blockAssistsHalfValue, who: who, inRally: inRally });
	var statsComplete;

	if (!opts.nototal) {
		// blockAssistsHalfValue: true, because that is the case for any aggregate
		var statsComplete2 = Stats.calc([data.agg], columns, { blockAssistsHalfValue: true, who: who, agg: true, inRally: inRally });
		statsComplete = statsComplete1.concat(statsComplete2);
	} else {
		statsComplete = statsComplete1;
	}

	if (opts.sort) {
		statsComplete.sort(opts.sort);
	}

	var configForCSV = exportPointLogDataCSVToServer(statsComplete, opponentName);

	// TODO: This is a memory leak if reloading data (live screen tabs). Is this needed as a global?
	if (webReport.currentTab < 19 ) {
		allStatsCSVForPointLogs += configForCSV + "\n";
	} else {
		allStatsCSVForPointLogs = configForCSV + "\n";
	}

	// now create the table
	for (let i=0, iLen=statsComplete.length; i < iLen; i++) {

		var tr = "";
		var analyze_select_val = 0;

		// Special handling for Point Log Adjustments
		if (statsComplete[i].hasOwnProperty("action_type_log") && statsComplete[i]["action_type_log"] === "Adjustment") {
			// ignore "game start"
			if (statsComplete[i]["action_log"] === "game start") {
				continue;
			}

			tr += '<td colspan="' + columns.length + '">' + statsComplete[i]["action_log"];
			if (statsComplete[i]["stage_log"]) {
				tr += " -&nbsp;Our&nbsp;score&nbsp;change:&nbsp;" + statsComplete[i]["stage_log"];
			}
			if (statsComplete[i]["rally_log"]) {
				tr += " -&nbsp;Their&nbsp;score&nbsp;change:&nbsp;" + statsComplete[i]["rally_log"];
			}
			tr += "</td>";

			statsComplete[i]["__class"] = "log_adjustment_row";
		} else if (statsComplete[i].hasOwnProperty("action_type_log") && statsComplete[i]["action_type_log"] === "substitution") {
			tr += '<td colspan="' + columns.length + '">';
			var substitution = statsComplete[i]["action_log"].split(' ');
			if (substitution[0] == "us") {
				tr += "Sub #" + substitution[1] + " for #" + substitution[3];
			} else {
				tr += "Sub #" + substitution[1] + " for #" + substitution[3];
			}
			tr += "</td>";

			statsComplete[i]["__class"] = "log_substitution_row";
		} else if (statsComplete[i].hasOwnProperty("action_type_log") && statsComplete[i]["action_type_log"] === "timeout") {
			tr += '<td colspan="' + columns.length + '">';
			var timeout = statsComplete[i]["action_log"].split(' ');
			if (timeout[0] == "us") {
				tr += "Our&nbsp;Timeout #" + timeout[1];
			} else {
				tr += "Their&nbsp;Timeout #" + timeout[1];
			}
			tr += "</td>";

			statsComplete[i]["__class"] = "log_timeout_row";
		} else {

			for (let j=0, jLen=columns.length; j < jLen; j++) {

				var columnKey = Stats.getColumnKey(columns[j]);
				var columnFmt = Stats.getColumnFmt(columns[j]);

				tr += "<td>";

				if (statsComplete[i].hasOwnProperty(columnKey)) {
					var value = statsComplete[i][columnKey];

					if (statsComplete[i].hasOwnProperty("__type") && statsComplete[i]["__type"] === "total") {
						// FUTURE
					} else {
						if (columnKey === "rot_serv") {
							if (value) {
								let matches = value.match(/(\d)+:(us|them)/);
								analyze_select_val = matches[1];

								let rotationNumber = translateRotation(matches[1]);
								if (matches[2] === "us") {
									value = "Rot " + rotationNumber + " serving";
								} else {
									value = "Rot " + rotationNumber + " receiving";
								}
							} else {
								value = "";
							}
						} else if (columnKey === "rot_serv_them") {
							if (value) {
								let matches = value.match(/(\d)+:(us|them)/);
								analyze_select_val = matches[1];

								let rotationNumber = translateRotation(matches[1]);
								if (matches[2] === "us") {
									value = "Rot " + rotationNumber + " receiving";
								} else {
									value = "Rot " + rotationNumber + " serving";
								}
							} else {
								value = "";
							}
						} else if (columnKey === "rot_serv_player") {
							if (value) {
								let matches = value.match(/(\d)+:(us|them):(\d+)/);
								let rotationNumber = translateRotation(matches[1]);
								if (matches[2] === "us") {
									value = "Rot " + rotationNumber + " serving";
								} else {
									value = "Rot " + rotationNumber + " receiving";
								}
								let player_name = webReport.getPlayerByTeamAndNum(webReport.currentTeam, matches[3]);
								value = player_name;
							} else {
								value = "";
							}

						} else if (columnKey === "rotation_log") {
							// Used in Point Log
							value = translateRotation(value);

						} else if (columnKey === "rotation") {
							// TODO: deprecated? not sure if this if used
							value = "Rot " + value;
						}
					}

					// apply special column formatting
					// TODO: change columnFmt to object
					switch (columnFmt) {
						case "minus":
							if (value === 0 || value === Stats.NOT_AVAILABLE) {
								value = '<span class="goodbad">' + value + '</span>';
							} else {
								value = '<span class="bad">-' + value + '</span>';
							}
							break;
						case "plus":
							if (value === 0 || value === Stats.NOT_AVAILABLE) {
								value = '<span class="goodbad">' + value + '</span>';
							} else {
								value = '<span class="good">+' + value + '</span>';
							}
							break;
						case "plus_minus":
							if (value === 0 || value === Stats.NOT_AVAILABLE) {
								value = '<span class="goodbad">' + value + '</span>';
							} else if (value > 0) {
								value = '<span class="good">+' + value + '</span>';
							} else {
								value = '<span class="bad">' + value + '</span>';
							}
							break;
						case "nobr":
							value = "<nobr>" + value + "</nobr>";
							break;
					}

					// ignore value if it contains #undefined
					if (value && value.toString().indexOf("#undefined") === -1) {
						tr += value;
					}
				}
				tr += "</td>";

			}

		}

		var row = $("<tr />").html(tr);
		if (statsComplete[i]["__class"]) {
			row.addClass(statsComplete[i]["__class"]);
		}

		// add meta data
		row.attr("data-analyze-select", analyze_select_val);

		statRows.push(row);
	}

	return statRows;
};

$(document).off("click", "#export_point_log").on("click", "#export_point_log", function () {

	var date = new Date();
	var filename =  webReport.getTeamNameCurrent() + "-PointLogs"  + "-" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + ".csv";
	filename = filename.replace(/ |\//g, '_');
	post_to_url("/solo/export", { filename: filename, data: allStatsCSVForPointLogs });
});

var csvHandleQuotes = function (text) {
	if (/,/.test(text)) {
		text.replace(/\\/, '\\\\');
		text.replace(/"/, '\\"');
		text = '"' + text + '"';
	} else if (/-/.test(text)) {
		if ((typeof text === "string" || text instanceof String)) {
			var stringMatchingRegEx = text.match("^[0-9]+ - [0-9]+$");
			if (stringMatchingRegEx) {
				text = text.replace(/-/g, "--");
			}
		}
	}
	// remove table styling hacks
	if (/<span class='bold-this-player-row' \/>/.test(text)){
		text = text.replace(/<span class='bold-this-player-row' \/>/, '');
	}
	if (/<span style="font-style:italic;">AGGREGATE<\/span>/.test(text)){
		text = text.replace(/<span style="font-style:italic;">AGGREGATE<\/span>/, 'AGGREGATE');
	}
	return text;
};

var exportPointLogDataCSVToServer = function(statsComplete, opponentName) {

	var configForCSV = "";
	var keysForColumns = [];

	configForCSV += csvHandleQuotes(opponentName) + "\n";

	$("#template_point_log").find("table th").each(function() {
		keysForColumns.push($(this).attr("data-key"));
		var text = $(this).text();
		configForCSV += csvHandleQuotes(text) + ",";
	});

	configForCSV += "\n";

	for (let i = 0; i < statsComplete.length; i++) {
		for (let j = 0; j < keysForColumns.length; j++) {
			if (statsComplete[i].action_type_log === "Adjustment") {
				if (statsComplete[i].action_log === "game start") {
					continue;
				}

				configForCSV += csvHandleQuotes(statsComplete[i].action_log);

				if (statsComplete[i].stage_log) {
					configForCSV += " - Our score change: " + statsComplete[i].stage_log + "\n";
				}
				if (statsComplete[i].rally_log) {
					configForCSV += " - Their score change: " + statsComplete[i].rally_log + "\n";
				}
				break;
			} else {
				configForCSV += csvHandleQuotes(statsComplete[i][keysForColumns[j]]) + ",";
			}
		}

		configForCSV += "\n";
	}

	return configForCSV;
};



var generateCSVReport = function (type, data, gridsList, settings, headingForCSVReport, useShortHeaders) {
	// NOTE: If making changes to this function, check if changes also need to be applied to publishReport.

	var playerSummaryConfig = [];
	var reportName = '';

	reportName = nameMapping[type] ? nameMapping[type] : '';

	if (reportName) {
		playerSummaryConfig = ReportConfig.getColumnsForGrid(reportName);

		if (reportName === "attack_by_setter" && settings.hasOwnProperty('setterIdx') && settings.setterIdx !== undefined){
			// processAttackBySetter creates separate targets for arbitrary number of setters;
			// use target corresponding to specified setter for this table
			type += settings.setterIdx;
		}
	}

	var config = [];

	for (let i = 0; i < playerSummaryConfig.length; i++) {
		let temp = [];

		for (let j = 0; j < playerSummaryConfig[i].columns.length; j++) {
			var columnsPlayer = {};
			columnsPlayer = {
				"key"       : playerSummaryConfig[i].columns[j].getKey(),
				"text"      : playerSummaryConfig[i].columns[j].getText(),
				"textShort" : playerSummaryConfig[i].columns[j].getTextShort(),
				"category"  : playerSummaryConfig[i].columns[j].getCategory(),
				"type"      : playerSummaryConfig[i].columns[j].getType(),
				"bgColor"   : playerSummaryConfig[i].columns[j].getBgColor(),
				"hasTotal"  : playerSummaryConfig[i].columns[j].getHasTotal()
			};
			temp.push(columnsPlayer);
		}

		config.push(temp);
	}

	var statsComplete = [];
	var inRally = webReport.buildInRallyBoolean(data);
	var columnsForCSV = [];
	var columnKeysForCSV = [];

	for (let i = 0; i < config.length; i++) {
		let temp = [];
		let tempHeaders = [];
		for (let j = 0; j < config[i].length; j++) {
			temp.push(config[i][j].key);
			if (useShortHeaders){
				tempHeaders.push(config[i][j].textShort);
			} else {
				tempHeaders.push(config[i][j].text);
			}
		}

		columnsForCSV.push(tempHeaders);
		columnKeysForCSV.push(temp);

		let blockAssistsHalfValue;
		if (
			type === 'byGame'              ||
			type === 'byRotation_1'        ||
			type === 'byRotation_2'        ||
			type === 'byRotServe'          ||
			type === 'byRotReceive'        ||
			type === 'byRotAnalyzeServe'   ||
			type === 'byRotAnalyzeReceive' ||
			type === 'byMatchAgg'          ||
			type === 'byGameAgg'           ||
			type === 'byOurRotationServe'  ||
			type === 'byOurRotationReceive'
		) {
			blockAssistsHalfValue = true;
		} else {
			blockAssistsHalfValue = false;
		}

		statsComplete[i] = Stats.calc(data.byRow, temp, { blockAssistsHalfValue: blockAssistsHalfValue, who: 'us', inRally: inRally });
	}

	var configForCSV = '';
	var configHeadersForCSV = '';
	var configDataForCSV = '';
	var configSummaryForCSV = '';
	var configSummaryDataForCSV = '';

	if (headingForCSVReport) {
		configForCSV += csvHandleQuotes(headingForCSVReport) + '\n';
	}

	var summaryData = [];

	for (let i = 0; i < config.length; i++) {
		let temp = [];
		for (let j = 0; j < config[i].length; j++) {
			if (config[i][j].hasTotal === 0) {
				temp.push(config[i][j].key);
			}
		}

		// footer data is the aggregate, and block assists will always be half value
		let blockAssistsHalfValue;
		if (
			type === 'byGameStats'  ||
			type === 'byMatchStats'
		) {
			blockAssistsHalfValue = false;
		} else {
			blockAssistsHalfValue = true;
		}

		summaryData[i] = Stats.calc([data.agg], temp, { blockAssistsHalfValue: blockAssistsHalfValue, who: 'us', inRally: inRally });
	}

	var sData = [];

	for (let i = 0; i < config.length; i++) {
		let temp = [];
		for (let j = 0; j < config[i].length; j++) {
			temp.push(config[i][j].key);
		}
		sData.push(temp);
	}

	var footerData = [];
	for (let i = 0; i < summaryData.length ||  i < sData.length; i++) {
		let temp = {};
		for (let j = 0; j < summaryData[i].length; j++) {
			for (let k = 0; k < sData[i].length; k++) {
				temp[sData[i][k]] = summaryData[i][j][sData[i][k]];
			}
		}
		footerData.push(temp);
	}

	for (let i = 0; i < statsComplete.length; i++) {
		configDataForCSV = '';
		configSummaryDataForCSV = '';
		configSummaryForCSV = 'Total';
		for (let j = 0; j < statsComplete[i].length; j++) {
			if (statsComplete[i][j]['rot_serv'] !== null) {
				statsComplete[i][j]['rot_serv'] = convertConventionToName(statsComplete[i][j], 'rot_serv');
			}
			if (statsComplete[i][j]['rot_serv_them'] !== null) {
				statsComplete[i][j]['rot_serv_them'] = convertConventionToName(statsComplete[i][j], 'rot_serv_them');
			}
			configHeadersForCSV = '';
			for (let k = 0; k < columnKeysForCSV[i].length; k++) {
				configHeadersForCSV += csvHandleQuotes(columnsForCSV[i][k]) + ', ';
				configDataForCSV += csvHandleQuotes(statsComplete[i][j][columnKeysForCSV[i][k]]) + ', ';
			}
			configDataForCSV += '\n';
			configHeadersForCSV += '\n';
		}

		for (let k = 0; k < columnKeysForCSV[i].length; k++) {
			if (footerData[i][columnKeysForCSV[i][k]] === null || footerData[i][columnKeysForCSV[i][k]] === undefined || footerData[i][columnKeysForCSV[i][k]] === '') {
				configSummaryDataForCSV += '' + ', ';
			} else {
				configSummaryDataForCSV += csvHandleQuotes(footerData[i][columnKeysForCSV[i][k]]) + ", ";
			}
		}

		configSummaryForCSV += configSummaryDataForCSV + "\n";
		if (settings.footerrow === false){
			configSummaryForCSV = "";
		}
		configForCSV += configHeadersForCSV + configDataForCSV + configSummaryForCSV + "\n";
	}

	return {
		'csvOutput': configForCSV,
		'statsComplete': statsComplete
	};
};



var createJQGrid = function(data, settings) {

	var prevIdServe = null;
	var prevIdReceive = null;
	var prevServeVisible = false;
	var prevReceiveVisible = false;
	var widthOfGrid = 0;
	var rowNum = 0;
	var sortOrder = "";
	var sortable = false;
	var topPager = false;

	if (settings.gridWidth > window.width) {
		widthOfGrid = window.width;
	} else {
		widthOfGrid = settings.gridWidth;
	}

	if (CONFIG.IS_MOBILE) {
		rowNum = CONFIG.ROWS_PER_GRID_PAGE_MOBILE;
		sortOrder = "";
		sortable = true;
		topPager = true;
	} else {
		rowNum = settings.options.rowNum;
		sortOrder = settings.options.sortorder;
		sortable = true;
		topPager = false;
	}

	$(settings.tableId).jqGrid({
		datatype         : "local",
		height           : "auto",
		width            : widthOfGrid,
		rowNum           : rowNum,
		viewrecords      : true,
		hoverrows        : settings.options.hoverrows,
		cmTemplate       : { title: false },
		sortable         : sortable,
		sortname         : "",
		sortorder        : sortOrder,
		hidegrid         : settings.options.hidegrid,
		colNames         : settings.colNames,
		colModel         : settings.colModel,
		data             : data,
		caption          : settings.options.caption,
		footerrow        : settings.options.footerrow,
		userDataOnFooter : settings.options.userDataOnFooter,
		loadComplete     : settings.options.loadComplete,
		gridview         : true,
		toppager         : topPager,
		onSelectRow : function (ids) {
			// This only runs for the "Analyze" tab
			if (settings.tableId !== "#byRotServe_1_grid" && settings.tableId !== "#byRotReceive_1_grid") {
				return;
			}

			var rotNum = ids;

			if (settings.options.hoverrows) {
				if (settings.options.isServing) {
					for (let k = 0; k < Columns.TotalCategories.length; k++) {
						if ($("#byRotAnalyzeServe_"+(k+1)+"_grid").length) {
							$.jgrid.gridUnload("#byRotAnalyzeServe_"+(k+1)+"_grid");
						}
					}
					$("#modal_view_byRotAnalyzeServe").empty();

					if (prevServeVisible) {
						if (prevIdServe === rotNum) {
							prevServeVisible = false;
						} else {
							analyzeDrillDown(rotNum, settings.options.isServing);
							prevServeVisible = true;
						}
					} else {
						analyzeDrillDown(rotNum, settings.options.isServing);
						prevServeVisible = true;
					}
					prevIdServe = rotNum;
				} else {
					// TODO: DRY (previous scope)
					for (let k = 0; k < Columns.TotalCategories.length; k++) {
						if ($("#byRotAnalyzeReceive_"+(k+1)+"_grid").length > 0) {
							$.jgrid.gridUnload("#byRotAnalyzeReceive_"+(k+1)+"_grid");
						}
					}
					$("#modal_view_byRotAnalyzeReceive").empty();
					if (prevReceiveVisible) {
						if (prevIdReceive === rotNum) {
							prevReceiveVisible = false;
						} else {
							analyzeDrillDown(rotNum, settings.options.isServing);
							prevReceiveVisible = true;
						}
					} else {
						analyzeDrillDown(rotNum, settings.options.isServing);
						prevReceiveVisible = true;
					}
					prevIdReceive = rotNum;
				}
			} else {
				return;
			}
		},
		onInitGrid: function() {
			$(settings.tableId+"_toppager").hide();
		},
		gridComplete: function() {
			let totalRecords = $(settings.tableId).jqGrid("getGridParam", "records");
			let rowNumCount = $(settings.tableId).jqGrid("getGridParam", "rowNum");
			if (totalRecords > rowNumCount) {
				$(settings.tableId+"_toppager").show();
			}
			$(settings.tableId+"_toppager").find(".ui-pg-button>span.ui-icon-seek-first")
				.removeClass("ui-icon ui-icon-seek-first")
				.addClass("fa fa-fast-backward pagerClass");
			$(settings.tableId+"_toppager").find(".ui-pg-button>span.ui-icon-seek-prev")
				.removeClass("ui-icon ui-icon-seek-prev")
				.addClass("fa fa-backward pagerClass");
			$(settings.tableId+"_toppager").find(".ui-pg-button>span.ui-icon-seek-next")
				.removeClass("ui-icon ui-icon-seek-next")
				.addClass("fa fa-forward pagerClass");
			$(settings.tableId+"_toppager").find(".ui-pg-button>span.ui-icon-seek-end")
				.removeClass("ui-icon ui-icon-seek-end")
				.addClass("fa fa-fast-forward pagerClass");

			// hack to make player rows bold - set in webReport.processTournamentComparisonByPlayerStats
			webReport.makeTournamentPlayerRowsBold();
		},
	});
};

var convertConventionToName = function(data, key) {

	let value = data[key];

	if (key === "rot_serv") {
		if (value) {
			let matches = value.match(/(\d)+:(us|them)/);

			let rotationNumber = translateRotation(matches[1]);
			if (matches[2] === "us") {
				value = "Rot " + rotationNumber + " serving";
			} else {
				value = "Rot " + rotationNumber + " receiving";
			}
		} else {
			value = "";
		}
	} else if (key === "rot_serv_them") {
		if (value) {
			let matches = value.match(/(\d)+:(us|them)/);

			let rotationNumber = translateRotation(matches[1]);
			if (matches[2] === "us") {
				value = "Rot " + rotationNumber + " receiving";
			} else {
				value = "Rot " + rotationNumber + " serving";
			}
		} else {
			value = "";
		}
	} else if (key === "rot_serv_player") {
		if (value) {
			let matches = value.match(/(\d)+:(us|them):(\d+)/);
			let rotationNumber = translateRotation(matches[1]);
			if (matches[2] === "us") {
				value = "Rot " + rotationNumber + " serving";
			} else {
				value = "Rot " + rotationNumber + " receiving";
			}
			let player_name = webReport.getPlayerByTeamAndNum(webReport.currentTeam, matches[3]);
			value = player_name;
		} else {
			value = "";
		}
	} else if (key === "rotation") {
		// TODO: deprecated? not sure if this if used
		value = "Rot " + value;
	}


	return value;
};


// TODO: rename to players if possible
// TODO: make this the default way to get player listings
var playersObject = {

	data: {},

	sortByNum: function (a, b) {
		return parseInt(a.num, 10) - parseInt(b.num, 10);
	},

	sortByOrder: function (a, b) {
		return parseInt(a.order, 10) - parseInt(b.order, 10);
	},

	getByTeam: function (teamId) {
		return this.data[teamId].slice().sort(playersObject.sortByOrder);
	},

	initTeam: function (teamId, playerArray) {

		this.data[teamId] = [];

		for (let i=0, ilen=playerArray.length; i < ilen; i++) {
			var name = playerArray[i].name;
			var num  = playerArray[i].num;
			if (!name) {
				name = '(N/A)';
			}
			this.data[teamId].push({
				order: i,
				num: num,
				name: name
			});
		}

	},
	// TODO: migrate all calls to here
	//
	getPlayerByTeamAndNum: function (teamId, playerNum) {
		var playerName = webReport.get_xteam()[teamId].players2[playerNum];
		// If the player has been deleted from the team list, they will not
		// have a name
		if (!playerName) {
			playerName = '(N/A)';
		}
		return playerName;
	},

	getPlayerNumsByTeam: function (teamId) {
		var playerNums = [];

		// 0 is a valid teamId
		if (teamId >= 0) {
			if (webReport.get_xteam()[teamId]) {
				for (let playerNum in webReport.get_xteam()[teamId].players2) {
					playerNums.push(playerNum);
				}
			}
		}

		return playerNums;
	},

	getPlayerNumNameMapping:function (teamId) {
		var mapping = {};

		for (let playerNum in webReport.get_xteam()[teamId].players2) {
			mapping[playerNum] = this.getPlayerByTeamAndNum(teamId, playerNum);
		}

		return mapping;
	},

	convertPlayerStatstoJerseys:function (player_stats) {
		for (let i=0, ilen=player_stats.byRow.length; i < ilen; i++) {
			player_stats.byRow[i].player_num = webReport.get_xteam()[webReport.currentTeam].playerJerseys[player_stats.byRow[i].player_num];
		}
	},

	convertPlayerLogstoJerseys:function (player_logs) {
		for (let i=0, ilen=player_logs.byRow.length; i < ilen; i++) {
			if (player_logs.byRow[i].playername != 'Their Player') {
				player_logs.byRow[i].playernum = webReport.get_xteam()[webReport.currentTeam].playerJerseys[player_logs.byRow[i].playernum];
			}
			if (player_logs.byRow[i].asstnum !== undefined) {
				player_logs.byRow[i].asstnum = webReport.get_xteam()[webReport.currentTeam].playerJerseys[player_logs.byRow[i].asstnum];
			}
			if (player_logs.byRow[i].action_type_log == 'substitution') {
				var entry = player_logs.byRow[i].action_log.split(' ');
				entry[1] = webReport.get_xteam()[webReport.currentTeam].playerJerseys[entry[1]];
				entry[3] = webReport.get_xteam()[webReport.currentTeam].playerJerseys[entry[3]];
				player_logs.byRow[i].action_log = entry.join(' ');
			}
		}
	}
};

var containsCategoryInResultArray = function(cat, arr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].category == cat) {
			return true;
		}
	}

	return false;
};

var removeCategoryFromResultArray = function(cat, arr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].category == cat) {
			arr.splice(i, 1);
		}
	}
	return arr;
};

var isSaveButtonClicked = false;

var findListTemplate = function(reportName, reportType, isDefault) {
	var result;

	if (isDefault) {
		result = Columns.getAllForConfigUi(reportName, reportType, isDefault);
	} else {
		result = Columns.getAllForConfigUi(reportName, reportType);
	}

	var template = $(document).find('#listTemplate');
	var list = $(document).find('#list_data_container_'+reportType);
	$("#list_data_container_"+reportType).empty();
	template.tmpl(result).appendTo(list);
};

var findTableConfigTemplate = function(data, reportType) {
	var template_config_table = $(document).find('#table_config_template');
	var table = $(document).find('#table_select_container_'+reportType).find('#table_select_'+reportType+"> tbody");
	$("#table_select_"+reportType+"> tbody").empty();

	template_config_table.tmpl(data, { lengthOfArray: data.length }).appendTo(table);
};

var findModalTemplate = function(reportType) {
	$('.modal-backdrop').remove();
	var template_modal = $(document).find('#modalTemplate');
	var divToRender = $(document).find('#modal_view_'+reportType);
	$("#modal_view_"+reportType).empty();
	template_modal.tmpl([{reportType: reportType}]).appendTo(divToRender);
};

var findModalTemplateForCSVExportForBasciVersionOfWebreports = function(reportType) {
	var template_modal = $(document).find('#modalTemplateForCSVExport');
	var divToRender = $(document).find('#modal_view_'+reportType);
	$("#modal_view_"+reportType).empty();
	template_modal.tmpl([{reportType: reportType}]).appendTo(divToRender);
};

var createAndPopulateTemplateForReport = function(reportName, reportType) {
	var categoriesInTable = [];
	if (isDemoAccount(App.getSoloUid())) {
		//alert("Customize functionality is not available in the demo");
	} else if (App.isPro()){
		findModalTemplate(reportType);
		findListTemplate(reportName, reportType);
		categoriesInTable = ReportConfig.getTableNumberForCategory(reportName, reportType);
		findTableConfigTemplate(categoriesInTable, reportType);
	} else {
		findModalTemplateForCSVExportForBasciVersionOfWebreports(reportType);
	}

	return categoriesInTable;
};

// TODO: see if we can un-globalize thesee
var statsConfigForLargeReport = "";
var isModalVisible = false;

var clearStatsConfigForLargeReport = () => {
	statsConfigForLargeReport = "";
};

const nameMapping = {
	byPlayer             : "summary_by_player",
	byGame               : "summary_by_game",
	byRotation_1         : "summary_by_rotation_1",
	byRotation_2         : "summary_by_rotation_2",
	byRotServe           : "analyze_rot_serv",
	byRotReceive         : "analyze_rot_rcv",
	byRotAnalyzeServe    : "analyze_drill_rotation_serve",
	byRotAnalyzeReceive  : "analyze_drill_rotation_receive",
	byPlayerRanking      : "player_ranking",
	byPlayerEef          : "player_eef",
	byMatchAgg           : "match_agg",
	byGameAgg            : "game_agg",
	byGameStats          : "player_by_game",
	byMatchStats         : "player_by_match",
	byOurRotationServe   : "rot_our_ser",
	byOurRotationReceive : "rot_our_rcv",
	byRotPlayerServe     : "rot_player_serve",
	byRotPlayerReceive   : "rot_player_receive",
	byBoxScores          : "box_scores",
	byBoxScoresTeam      : "box_scores_team",
	coach_byPlayer       : "coach_summary_by_player",
	coachm_byPlayer      : "coach_summary_by_player",
	attackBySetter       : "attack_by_setter",
	summaryByTournament  : "summary_by_tournament",
	tournamentComparisonByPlayer : "tournament_comparison_by_player",
	summaryByMatch       : "summary_by_match",
	matchComparisonByPlayer : "match_comparison_by_player"
};

var publishReport = function (type, data, gridsList, settings, headingForCSVReport) { //, pagerId) {
	// NOTE: If making changes to this function, check if changes also need to be applied to generateCSVReport.

	var playerSummaryConfig = [];
	var reportName = '';
	var listOfGrids = [];
	var gridName = "";
	var categoriesInTable = [];
	var changeFlag = false;
	var sandwichDetected = false; // detecting "sandwiched" stats (one category between 2 instances of another
	                              // category) which cause layout issues

	window.width = $(document).width() - (0.035 * $(document).width());

	reportName = nameMapping[type] ? nameMapping[type] : '';

	if (reportName) {
		playerSummaryConfig = ReportConfig.getColumnsForGrid(reportName);
		categoriesInTable = createAndPopulateTemplateForReport(reportName, type);

		if (reportName === "attack_by_setter" && settings.hasOwnProperty('setterIdx') && settings.setterIdx !== undefined){
			// processAttackBySetter creates separate targets for arbitrary number of setters;
			// use target corresponding to specified setter for this table
			type += settings.setterIdx;
		}
	}

	var config = [];
	var configHeaders = [];
	var categoriesPresent = [];

	var refTarget =  "SOLO_"+type+"_target";

	if (gridsList.length > 0) { //&& pagerId) {
		for (let j = 0; j < gridsList.length; j++) {
			if ($('#' + gridsList[j]).length) {
				$.jgrid.gridUnload("#"+gridsList[j]);
				$("#"+gridsList[j]).empty();
			}

			if ( j < playerSummaryConfig.length) {

				if ($("#"+gridsList[j]).length <= 0) {
					$("#"+refTarget).after($("<table/>").attr("id", gridsList[j]));
					$("#"+gridsList[j]).before($("<br>"));
				}

				refTarget = gridsList[j];

				listOfGrids.push("#"+gridsList[j]);
			}
		}
	}

	for (let i = 0; i < playerSummaryConfig.length; i++) {
		let temp = [];
		let tempHeaders = [];
		categoriesPresent = [];

		if (gridsList.length <= 0) {
			gridName = type + "_" + (i+1) + "_grid";

			if ($("#"+gridName).length) {
				$.jgrid.gridUnload("#"+gridName);
				$("#"+gridName).empty();
			}

			// pagerId = "pager_"+type+i;

			if ($("#"+gridName).length <= 0) {
				$("#"+refTarget).after($("<table/>").attr("id", gridName));
				$("#"+gridName).before($("<br>"));
			}

			refTarget = gridName;

			listOfGrids.push("#"+gridName);
		}

		for (let j = 0; j < playerSummaryConfig[i].columns.length; j++) {
			var columnsPlayer = {};
			columnsPlayer = {
				"key"       : playerSummaryConfig[i].columns[j].getKey(),
				"text"      : playerSummaryConfig[i].columns[j].getText(),
				"textShort" : playerSummaryConfig[i].columns[j].getTextShort(),
				"category"  : playerSummaryConfig[i].columns[j].getCategory(),
				"type"      : playerSummaryConfig[i].columns[j].getType(),
				"bgColor"   : playerSummaryConfig[i].columns[j].getBgColor(),
				"hasTotal"  : playerSummaryConfig[i].columns[j].getHasTotal()
			};
			temp.push(columnsPlayer);

			if (categoriesPresent.slice(-1)[0] !== columnsPlayer.category){
				categoriesPresent.push(columnsPlayer.category);
			}
		}

		// detect "sandwiched" stats (one category between 2 instances of another category) which cause layout issues
		if (_.uniq(categoriesPresent).length !== categoriesPresent.length){
			sandwichDetected = true;
		}

		for (let k = 0; k < playerSummaryConfig[i].headers.length; k++) {
			var columnHeaders = {};
			columnHeaders = {
				"titleText"       : playerSummaryConfig[i].headers[k]['titleText'],
				"startColumnName" : playerSummaryConfig[i].headers[k]['startColumnName'],
				"numberOfColumns" : playerSummaryConfig[i].headers[k]['numberOfColumns']
			};
			tempHeaders.push(columnHeaders);
		}
		config.push(temp);
		configHeaders.push(tempHeaders);
	}

	var statsComplete = [];
	var inRally = webReport.buildInRallyBoolean(data);
	var columnsForCSV = [];
	var columnKeysForCSV = [];

	for (let i = 0; i < config.length; i++) {
		let temp = [];
		let tempHeaders = [];
		for (let j = 0; j < config[i].length; j++) {
			temp.push(config[i][j].key);
			tempHeaders.push(config[i][j].text);
		}

		columnsForCSV.push(tempHeaders);
		columnKeysForCSV.push(temp);

		let blockAssistsHalfValue;
		if (
			type === 'byGame'              ||
			type === 'byRotation_1'        ||
			type === 'byRotation_2'        ||
			type === 'byRotServe'          ||
			type === 'byRotReceive'        ||
			type === 'byRotAnalyzeServe'   ||
			type === 'byRotAnalyzeReceive' ||
			type === 'byMatchAgg'          ||
			type === 'byGameAgg'           ||
			type === 'byOurRotationServe'  ||
			type === 'byOurRotationReceive'
		) {
			blockAssistsHalfValue = true;
		} else {
			blockAssistsHalfValue = false;
		}

		statsComplete[i] = Stats.calc(data.byRow, temp, { blockAssistsHalfValue: blockAssistsHalfValue, who: 'us', inRally: inRally });
	}

	var configForCSV = '';
	var configHeadersForCSV = '';
	var configDataForCSV = '';
	var configSummaryForCSV = '';
	var configSummaryDataForCSV = '';

	if (headingForCSVReport) {
		configForCSV += csvHandleQuotes(headingForCSVReport) + '\n';
	}

	var summaryData = [];

	for (let i = 0; i < config.length; i++) {
		let temp = [];
		for (let j = 0; j < config[i].length; j++) {
			if (config[i][j].hasTotal === 0) {
				temp.push(config[i][j].key);
			}
		}

		// footer data is the aggregate, and block assists will always be half value
		let blockAssistsHalfValue;
		if (
			type === 'byGameStats'  ||
			type === 'byMatchStats'
		) {
			blockAssistsHalfValue = false;
		} else {
			blockAssistsHalfValue = true;
		}

		summaryData[i] = Stats.calc([data.agg], temp, { blockAssistsHalfValue: blockAssistsHalfValue, who: 'us', inRally: inRally });
	}

	var sData = [];

	for (let i = 0; i < config.length; i++) {
		let temp = [];
		for (let j = 0; j < config[i].length; j++) {
			temp.push(config[i][j].key);
		}
		sData.push(temp);
	}

	var footerData = [];
	for (let i = 0; i < summaryData.length ||  i < sData.length; i++) {
		let temp = {};
		for (let j = 0; j < summaryData[i].length; j++) {
			for (let k = 0; k < sData[i].length; k++) {
				temp[sData[i][k]] = summaryData[i][j][sData[i][k]];
			}
		}
		footerData.push(temp);
	}

	for (let i = 0; i < statsComplete.length; i++) {
		configDataForCSV = '';
		configSummaryDataForCSV = '';
		configSummaryForCSV = 'Total';
		for (let j = 0; j < statsComplete[i].length; j++) {
			if (statsComplete[i][j]['rot_serv'] !== null) {
				statsComplete[i][j]['rot_serv'] = convertConventionToName(statsComplete[i][j], 'rot_serv');
			}
			if (statsComplete[i][j]['rot_serv_them'] !== null) {
				statsComplete[i][j]['rot_serv_them'] = convertConventionToName(statsComplete[i][j], 'rot_serv_them');
			}
			configHeadersForCSV = '';
			for (let k = 0; k < columnKeysForCSV[i].length; k++) {
				configHeadersForCSV += csvHandleQuotes(columnsForCSV[i][k]) + ', ';
				configDataForCSV += csvHandleQuotes(statsComplete[i][j][columnKeysForCSV[i][k]]) + ', ';
			}
			configDataForCSV += '\n';
			configHeadersForCSV += '\n';
		}

		for (let k = 0; k < columnKeysForCSV[i].length; k++) {
			if (footerData[i][columnKeysForCSV[i][k]] === null || footerData[i][columnKeysForCSV[i][k]] === undefined || footerData[i][columnKeysForCSV[i][k]] === '') {
				configSummaryDataForCSV += '' + ', ';
			} else {
				configSummaryDataForCSV += csvHandleQuotes(footerData[i][columnKeysForCSV[i][k]]) + ", ";
			}
		}

		configSummaryForCSV += configSummaryDataForCSV + "\n";
		if (settings.footerrow === false){
			configSummaryForCSV = "";
		}
		configForCSV += configHeadersForCSV + configDataForCSV + configSummaryForCSV + "\n";
	}

	statsConfigForLargeReport += configForCSV + "\n";

	$("#exportButton_"+type).click(function () {

		var date = new Date();
		var reportNameForFilename = reportName;
		if (reportName === 'attack_by_setter'){
			// add setter's name to output filename
			reportNameForFilename = reportName + '_' + $(this).parent().text().split(/Setter: #[\s\d]*(.*?) Export Data/)[1];
		}

		var filename =  webReport.getTeamNameCurrent() + "-" + reportNameForFilename + "-" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + ".csv";
		filename = filename.replace(/ |\//g, '_');

		if (
			reportName === "player_by_game"   ||
			reportName === "player_by_match"  ||
			reportName === "rot_player_serve" ||
			reportName === "rot_player_receive"
		) {

			post_to_url("/solo/export", { filename: filename, data: statsConfigForLargeReport });
		} else {
			post_to_url("/solo/export", { filename: filename, data: configForCSV });
		}
	});

	var showFooterData = function () {
		for (let i = 0; i < listOfGrids.length; i++) {
			jQuery(listOfGrids[i]).jqGrid("footerData", "set", footerData[i]);
		}
	};

	var colNames = [];

	for (let i = 0; i < config.length; i++) {
		let temp = [];
		for (let j = 0; j < config[i].length; j++) {
			temp.push(config[i][j].textShort);
		}
		colNames.push(temp);
	}

	var colModel = [];
	for (let i = 0; i < sData.length; i++) {
		let temp = [];
		for (let j = 0; j < sData[i].length; j++) {
			var colModelObject = {};
			var colWidth = Columns.get(sData[i][j]).getWidth();
			var colAlign = Columns.get(sData[i][j]).getAlign();

			if (CONFIG.IS_MOBILE) {
				colModelObject = {
					"name" : sData[i][j],
					"index": sData[i][j],
					"width": colWidth,
					"align": colAlign,
					"sorttype": Columns.get(sData[i][j]).getSortType()
				};
			} else {
				colModelObject = {
					"name" : sData[i][j],
					"index": sData[i][j],
					"width": colWidth,
					"align": colAlign,
					"sorttype": Columns.get(sData[i][j]).getSortType()
				};
			}

			temp.push(colModelObject);
		}
		colModel.push(temp);
	}

	for (let i = 0; i < colModel.length; i++) {
		var options = {
			caption          : settings.caption,
			hidegrid         : settings.hidegrid,
			footerrow        : settings.footerrow,
			userDataOnFooter : settings.userDataOnFooter,
			loadComplete     : showFooterData,
			hoverrows        : settings.hoverrows,
			isServing        : settings.isServing,
			rowNum           : data.byRow.length
		};

		var gridWidth = 0;

		for (let j = 0; j < colModel[i].length; j++) {
			gridWidth += colModel[i][j].width;
		}

		createJQGrid(statsComplete[i], {
			'tableId'	: listOfGrids[i],
			'colNames'	: colNames[i],
			'colModel'	: colModel[i],
			'options'	: options,
			'gridWidth'	: gridWidth,
		});
	}

	for (let i = 0; i < config.length; i++) {
		for (let k = 0; k < listOfGrids.length; k++) {
			for (let j = 0; j < config[i].length; j++) {
				$(listOfGrids[k]).jqGrid('setLabel', config[i][j].key, '', { "background":config[i][j].bgColor });
			}
		}

		var groupHeadersList = [];

		/* jshint loopfunc:true */
		$.each(configHeaders[i], function(index) {
			groupHeadersList.push({
				startColumnName: configHeaders[i][index].startColumnName,
				numberOfColumns: configHeaders[i][index].numberOfColumns,
				titleText: configHeaders[i][index].titleText
			});
		});

		$(listOfGrids[i]).jqGrid("setGroupHeaders", {
			useColSpanStyle: true,
			groupHeaders:groupHeadersList
		});

		if (type === "byRotAnalyzeServe" || type === "byRotAnalyzeReceive") {
			hiliteStatisticalOutliers(listOfGrids[i]);
		}
	}

	$(document).on("click", ".catCheck_"+type, function () {

		$(this).attr("checked", this.checked);

		var $parent  = $(this).parent();
		var $children = $parent.children();
		$children.find(":checkbox").attr("checked", this.checked);

		var catId = $parent.attr("id");

		var isExists = false;

		if (catId !== "" && catId !== "General") {
			isExists = containsCategoryInResultArray(catId, categoriesInTable);
		}

		var checkedValue = $(this).is(":checked");

		if (checkedValue) {
			if (!isExists && catId !== "" && catId !== "General") {
				categoriesInTable.push({category: catId, table: 0, reportType: type, cat_type: catId + "_" + type});
			}
		} else {
			categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
		}

		findTableConfigTemplate(categoriesInTable, type);
	});

	$(document).on("click", ".typeCheck_"+type, function () {

		$(this).attr("checked", this.checked);

		var $parent  = $(this).parent();
		var $children = $parent.children();
		$children.find(":checkbox").attr("checked", this.checked);

		var catId = $parent.parent().attr("id");

		if ($(".typeCheck_"+type+"_"+catId+":checked").length === $(".typeCheck_"+type+"_"+catId).length) {
			$parent.parent().find(".catCheck_"+type+"_"+catId).attr("checked", "checked");
		} else {
			$parent.parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");
		}

		var isExists = false;
		if (catId !== "" && catId !== "General") {
			isExists = containsCategoryInResultArray(catId, categoriesInTable);
		}

		var checkedValue = $(this).is(":checked");

		if (checkedValue) {
			if (!isExists) {
				categoriesInTable.push({category: catId, table: 0, reportType: type, cat_type: catId + '_' + type});
			}
		} else {
			if (!($parent.parent().find('.typeCheck_'+type+"_"+catId).is(':checked')) && !($parent.parent().find('.keyCheck_'+type+"_"+catId).is(':checked'))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			}
		}

		findTableConfigTemplate(categoriesInTable, type);
	});

	$(document).on("click", ".subCheck_"+type, function () {

		$(this).attr("checked", this.checked);

		var $parent  = $(this).parent();
		var $children = $parent.children();
		$children.find(":checkbox").attr("checked", this.checked);

		var catId = $parent.parent().parent().attr("id");

		if ($(".subCheck_"+type+"_"+catId).length === $(".subCheck_"+type+"_"+catId+":checked").length) {
			$parent.parent().find(".typeCheck_"+type+"_"+catId).attr("checked", "checked");
			if ($(".typeCheck_"+type+"_"+catId+":checked").length === $(".typeCheck_"+type+"_"+catId).length) {
				$parent.parent().parent().find(".catCheck_"+type+"_"+catId).attr("checked", "checked");
			}
		} else {
			$parent.parent().find(".typeCheck_"+type+"_"+catId).removeAttr("checked");
			if ($(".typeCheck_"+type+"_"+catId+":checked").length !== $(".typeCheck_"+type+"_"+catId).length) {
				$parent.parent().parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");
			}
		}

		var isExists = false;
		if (catId !== "" && catId !== "General") {
			isExists = containsCategoryInResultArray(catId, categoriesInTable);
		}

		var checkedValue = $(this).is(":checked");

		if (checkedValue) {
			if (!isExists) {
				categoriesInTable.push({category: catId, table: 0, reportType: type, cat_type: catId + "_" + type});
			}
		} else {
			if (!($parent.parent().parent().find(".subCheck_"+type+"_"+catId).is(":checked")) && !($parent.parent().parent().find(".keyCheck_"+type+"_"+catId).is(":checked"))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			}
		}

		findTableConfigTemplate(categoriesInTable, type);
	});

	$(document).on("click", ".keyCheck_"+type, function () {

		$(this).attr("checked", this.checked);

		var $parent  = $(this).parent();

		var catId = "";
		var typeId = "";
		var subCatId = "";

		if (!($(this).parent().parent().is("div"))) {
			catId = $(this).parent().parent().attr("id");
			typeId = "";
			subCatId = "";
		}
		if (!$(this).parent().parent().parent().is("div")) {
			catId = $(this).parent().parent().parent().attr("id");
			typeId = $(this).parent().parent().attr("id");
			subCatId = "";
		}
		if (!($(this).parent().parent().parent().parent().is("div"))) {
			catId = $(this).parent().parent().parent().parent().attr("id");
			typeId = $(this).parent().parent().parent().attr("id");
			subCatId = $(this).parent().parent().attr("id");
		}

		if (typeId !== "" && ($(".keyCheck_"+type+"_"+catId+"_"+typeId+":checked").length === $(".keyCheck_"+type+"_"+catId+"_"+typeId).length)) {
			$parent.parent().find(".typeCheck_"+type+"_"+catId+"_"+typeId).attr("checked", "checked");
		} else {
			$parent.parent().find(".typeCheck_"+type+"_"+catId+"_"+typeId).removeAttr("checked");
		}

		if (typeId !== "" && subCatId !== "" && ($(".keyCheck_"+type+"_"+catId+"_"+typeId+"_"+subCatId+":checked").length === $(".keyCheck_"+type+"_"+catId+"_"+typeId+"_"+subCatId).length)) {
			$parent.parent().find(".subCheck_"+type+"_"+catId+"_"+subCatId).attr("checked", "checked");
			if ($(".subCheck_"+type+"_"+catId+":checked").length === $(".subCheck_"+type+"_"+catId).length) {
				$parent.parent().parent().find(".typeCheck_"+type+"_"+catId).attr("checked", "checked");
			}
		} else {
			$parent.parent().find(".subCheck_"+type+"_"+catId+"_"+subCatId).removeAttr("checked");
			if ($(".subCheck_"+type+"_"+catId+":checked").length !== $(".subCheck_"+type+"_"+catId).length) {
				$parent.parent().parent().find(".typeCheck_"+type+"_"+catId).removeAttr("checked");
			}
		}

		if ($(".keyCheck_"+type+"_"+catId+":checked").length === $(".keyCheck_"+type+"_"+catId).length) {
			$parent.parent().find(".catCheck_"+type+"_"+catId).attr("checked", "checked");

			if (!($parent.parent().parent().is("div"))) {
				$parent.parent().parent().find('.catCheck_'+type+"_"+catId).attr("checked", "checked");
			}

			if (!($parent.parent().parent().parent().is("div"))) {
				$parent.parent().parent().parent().find(".catCheck_"+type+"_"+catId).attr("checked", "checked");
			}
		} else {
			$parent.parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");

			if (!($parent.parent().parent().is("div"))) {
				$parent.parent().parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");
			}

			if (!($parent.parent().parent().parent().is("div"))) {
				$parent.parent().parent().parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");
			}
		}

		var isExists = false;
		if (catId !== "" && catId !== "General") {
			isExists = containsCategoryInResultArray(catId, categoriesInTable);
		}

		var checkedValue = $(this).is(":checked");

		if (checkedValue) {
			if (!isExists) {
				if (catId !== "" && catId !== "General") {
					categoriesInTable.push({category: catId, table: 0, reportType: type, cat_type: catId + "_" + type});
				}
			}
		} else {
			if (!($(this).parent().parent().find(".keyCheck_"+type+"_"+catId).is(":checked"))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			} else if (!($(this).parent().parent().parent().find(".keyCheck_"+type+"_"+catId).is(":checked"))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			} else if (!($(this).parent().parent().parent().parent().find(".keyCheck_"+type+"_"+catId).is(":checked"))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			}
		}

		findTableConfigTemplate(categoriesInTable, type);
	});

	$(document).on("change", "select", function() {
		changeFlag = true;

		var category = $(this).attr("id");
		var option = "";

		if ($("select option:selected").parent().hasClass("select_"+type)) {
			option = $(this).val();
		}

		for (let i = 0; i < categoriesInTable.length; i++) {
			if (categoriesInTable[i].category == category) {
				categoriesInTable[i].table = option;
				categoriesInTable[i].reportType = type;
				categoriesInTable[i].cat_type = category + "_" + type;
			}
		}
	});

	$(document).on('change', ':checkbox', function() {
		changeFlag = true;
	});

	// clear the previous handler
	$("#save_config_button_"+type).off('click');
	$("#reset_defaults_button_"+type).off('click');

	// attach the new handler
	$("#save_config_button_"+type).click(function () {
		saveUserColumnsHandler(type, changeFlag, reportName);

		if (changeFlag) {
			for (let i = 0; i < listOfGrids.length; i++) {
				if ($(listOfGrids[i]).length) {
					$.jgrid.gridUnload(listOfGrids[i]);
					$(listOfGrids[i]).empty();
				}
			}

			if (type === "byGameStats" || type === "byMatchStats" || type === "byRotPlayerServe" || type === "byRotPlayerReceive") {
				callProcessStatsForType(type);
			} else {
				publishReport(type, data, [], settings, headingForCSVReport);
			}
		}

		cleanUpBootstrapModalClose();
	});

	$("#reset_defaults_button_"+type).click(function () {
		findListTemplate(reportName, type, true);
		var defaultCategoriesInTable = ReportConfig.getTableNumberForCategory(reportName, type, true);
		var defaultPlayerSummaryConfig = ReportConfig.getColumnsForGrid(reportName, true);
		findTableConfigTemplate(defaultCategoriesInTable, type);

		if (
			(JSON.stringify(defaultCategoriesInTable) !== JSON.stringify(categoriesInTable))
			|| (JSON.stringify(defaultPlayerSummaryConfig) !== JSON.stringify(playerSummaryConfig))
		) {
			changeFlag = true;
			categoriesInTable = defaultCategoriesInTable;
			$.alert("Reset Complete.", "INFO");
			// alert('reset complete');
		} else {
			changeFlag = false;
			$.alert("Nothing to Reset.", "INFO");
			// alert('nothing to reset');
		}

		$("#reset_defaults_button_"+type).css('outline', 0);
	});

	$(document).on('hidden', '#modal_'+type, function(){
		if (!isSaveButtonClicked) {
			findListTemplate(reportName, type);

			categoriesInTable = ReportConfig.getTableNumberForCategory(reportName, type);

			findTableConfigTemplate(categoriesInTable, type);
		}

		isSaveButtonClicked = false;
		isModalVisible = false;
	});

	$(document).on('show', '#modal_'+type, function(){
		$('#table_select_container_'+type).show();
		isModalVisible = true;
	});

	$(document).on('shown', '#modal_'+type, function(){
		$('#modal_table_container_'+type).scrollTop(0);
		isModalVisible = true;
	});

	$(document).on('hide', '#modal_'+type, function(){
		$('#table_select_container_'+type).hide();
		isModalVisible = false;
	});

	// Detect "sandwiched" stats (one category between 2 instances of another category) which cause layout issues.
	// If detected, eliminate sandwich by re-saving the config and reloading the table in the background.
	if (sandwichDetected && App.isPro()){
		sandwichDetected = false;
		saveUserColumnsHandler(type, true, reportName);
		window.setTimeout(function(){
			$('<div id="tempmsg" style="float:right; font-size:1.1em; font-style:italic;">Refreshing tables...</div>').insertAfter($('.subsection_header:visible').get(0));
		}, 1000);
		window.setTimeout(function(){
			for (let i = 0; i < listOfGrids.length; i++) {
				$.jgrid.gridUnload(listOfGrids[i]);
				$(listOfGrids[i]).empty();
			}
			callProcessStatsForType(type);
			$('#tempmsg').remove();
		}, 3000);
	}

	// save processed stats for use in graphs
	wrGraph.currentReport = { byRow: statsComplete, agg: summaryData };

};

var publishReportForBoxScoresTabForTeam = function(type, data, gridsList, settings, headingForCSVReport, isAggregate, headingId) {
	var config = [];
	var configHeaders = [];
	var summaryDataForUs = [];
	var summaryDataForOpponent = [];
	var columnsForCSV = [];
	var columnKeysForCSV = [];
	var changeFlag = false;

	var reportName = 'box_scores_team';

	var refTarget =  "SOLO_"+type+"_target";

	var template_values = {
		match_name: headingForCSVReport,
		header_id: headingId
	};

	var template = $(document).find('#template_box_scores_team');
	var template_filled = $.tmpl(template, template_values, {
	});

	var playerSummaryConfig = ReportConfig.getColumnsForGrid(reportName);
	var categoriesInTable = createAndPopulateTemplateForReport(reportName, type);

	var listOfGrids = [];

	$("#"+refTarget).after(template_filled);
	refTarget = $('#SOLO_box_scores_wrapper').find('h4').attr('id');

	if (gridsList.length > 0) {
		for (let j = 0; j < gridsList.length; j++) {
			if ($("#"+gridsList[j]).length) {
				$.jgrid.gridUnload("#"+gridsList[j]);
				$("#"+gridsList[j]).empty();
			}

			if (j < playerSummaryConfig.length) {

				if ($("#"+gridsList[j]).length <= 0) {
					$("#"+refTarget).after($("<table/>").attr("id", gridsList[j]));
					$("#"+gridsList[j]).before($("<br>"));
				}
				refTarget = gridsList[j];

				listOfGrids.push("#"+gridsList[j]);
			}
		}
	}

	for (let i = 0; i < playerSummaryConfig.length; i++) {
		let temp = [];
		let tempHeaders = [];

		if (gridsList.length <= 0) {
			var gridName = type + "_" + (i+1) + "_grid";

			if ($("#"+gridName).length) {
				$.jgrid.gridUnload("#"+gridName);
				$("#"+gridName).empty();
			}

			if ($("#"+gridName).length <= 0) {
				$("#"+refTarget).after($("<table/>").attr("id", gridName));
			}
			$("#"+gridName).before($("<br>"));
			refTarget = gridName;

			listOfGrids.push("#"+gridName);
		}

		for (let j = 0; j < playerSummaryConfig[i].columns.length; j++) {
			var columnsPlayer = {};
			columnsPlayer = {
				"key"       : playerSummaryConfig[i].columns[j].getKey(),
				"text"      : playerSummaryConfig[i].columns[j].getText(),
				"textShort" : playerSummaryConfig[i].columns[j].getTextShort(),
				"category"  : playerSummaryConfig[i].columns[j].getCategory(),
				"type"      : playerSummaryConfig[i].columns[j].getType(),
				"bgColor"   : playerSummaryConfig[i].columns[j].getBgColor(),
				"hasTotal"  : playerSummaryConfig[i].columns[j].getHasTotal()
			};
			temp.push(columnsPlayer);
		}

		for (let k = 0; k < playerSummaryConfig[i].headers.length; k++) {
			var columnHeaders = {};
			columnHeaders = {
				"titleText"       : playerSummaryConfig[i].headers[k]['titleText'],
				"startColumnName" : playerSummaryConfig[i].headers[k]['startColumnName'],
				"numberOfColumns" : playerSummaryConfig[i].headers[k]['numberOfColumns']
			};
			tempHeaders.push(columnHeaders);
		}
		config.push(temp);
		configHeaders.push(tempHeaders);
	}

	var inRally = webReport.buildInRallyBoolean(data);

	for (let i = 0; i < config.length; i++) {
		let columns = [];
		let tempHeaders = [];
		let temp = [];
		for (let j = 0; j < config[i].length; j++) {
			if (config[i][j].hasTotal === 0) {
				columns.push(config[i][j].key);
			}
			temp.push(config[i][j].key);
			tempHeaders.push(config[i][j].text);
		}

		columnsForCSV.push(tempHeaders);
		columnKeysForCSV.push(temp);

		// footer data is the aggregate, and block assists will always be half value
		var blockAssistsHalfValue = true;

		if (isAggregate) {
			summaryDataForUs.push(Stats.calc([data.agg], columns, { blockAssistsHalfValue: blockAssistsHalfValue, who: 'us', inRally: inRally }));
			summaryDataForOpponent.push(Stats.calc([data.agg], columns, { blockAssistsHalfValue: blockAssistsHalfValue, who: 'them', inRally: inRally }));
		} else {
			summaryDataForUs.push(Stats.calc(data.byRow, columns, { blockAssistsHalfValue: blockAssistsHalfValue, who: 'us', inRally: inRally }));
			summaryDataForOpponent.push(Stats.calc(data.byRow, columns, { blockAssistsHalfValue: blockAssistsHalfValue, who: 'them', inRally: inRally }));
		}
	}

	var sData = [];
	for (let i = 0; i < config.length; i++) {
		let temp = [];
		for (let j = 0; j < config[i].length; j++) {
			temp.push(config[i][j].key);
		}
		sData.push(temp);
	}

	var colNames = [];

	for (let i = 0; i < config.length; i++) {
		let temp = [];
		for (let j = 0; j < config[i].length; j++) {
			temp.push(config[i][j].textShort);
		}
		colNames.push(temp);
	}

	var colModel = [];
	for (let i = 0; i < sData.length; i++) {
		let temp = [];
		for (let j = 0; j < sData[i].length; j++) {
			let colModelObject = {};
			let colWidth = Columns.get(sData[i][j]).getWidth();
			let colAlign = Columns.get(sData[i][j]).getAlign();
			colModelObject = {
				"name" : sData[i][j],
				"index": sData[i][j],
				"width": colWidth,
				"align": colAlign,
				"sorttype": Columns.get(sData[i][j]).getSortType()
			};
			temp.push(colModelObject);
		}
		colModel.push(temp);
	}

	for (let i = 0; i < colModel.length; i++) {
		let options = {
			caption          : settings.caption,
			hidegrid         : settings.hidegrid,
			footerrow        : settings.footerrow,
			userDataOnFooter : settings.userDataOnFooter,
			hoverrows        : settings.hoverrows,
			isServing        : settings.isServing,
		};

		for (let j = 0; j < summaryDataForUs[i].length; j++) {
			if (summaryDataForUs[i][j]['team_name'] === null) {
				summaryDataForUs[i][j]['team_name'] = 'us';
			}
		}

		let gridWidth = 0;

		for (let j = 0; j < colModel[i].length; j++) {
			gridWidth += colModel[i][j].width;
		}

		createJQGrid(summaryDataForUs[i], {
			'tableId'	: listOfGrids[i],
			'colNames'	: colNames[i],
			'colModel'	: colModel[i],
			'options'	: options,
			'gridWidth'	: gridWidth
		});
	}

	for (let i = 0; i < config.length; i++) {
		for (let k = 0; k < listOfGrids.length; k++) {
			for (let j = 0; j < config[i].length; j++) {
				$(listOfGrids[k]).jqGrid('setLabel', config[i][j].key, '', {"background":config[i][j].bgColor});
			}
		}

		let groupHeadersList = [];

		/* jshint loopfunc:true */
		$.each(configHeaders[i], function(index) {
			groupHeadersList.push({
				startColumnName: configHeaders[i][index].startColumnName,
				numberOfColumns: configHeaders[i][index].numberOfColumns,
				titleText: configHeaders[i][index].titleText
			});
		});

		$(listOfGrids[i]).jqGrid('setGroupHeaders', {
			useColSpanStyle: true,
			groupHeaders:groupHeadersList
		});
	}

	var configForCSV = '';
	var configHeadersForCSV = '';
	var configDataForCSV = '';

	configForCSV += csvHandleQuotes(headingForCSVReport) + '\n';

	for (let i = 0; i < listOfGrids.length; i++) {
		for (let j = 0; j < summaryDataForOpponent[i].length; j++) {
			if (summaryDataForOpponent[i][j]['team_name'] === null) {
				summaryDataForOpponent[i][j]['team_name'] = 'opponent';
			}
		}
		$(listOfGrids[i]).jqGrid('addRowData', 0, summaryDataForOpponent[i], "last");
	}

	for (let i = 0; i < summaryDataForUs.length; i++) {
		configDataForCSV = '';

		for (let j = 0; j < summaryDataForUs[i].length; j++) {
			configHeadersForCSV = '';
			for (let k = 0; k < columnKeysForCSV[i].length; k++) {
				configHeadersForCSV += csvHandleQuotes(columnsForCSV[i][k]) + ', ';
				configDataForCSV += csvHandleQuotes(summaryDataForUs[i][j][columnKeysForCSV[i][k]]) + ', ';
			}
			configDataForCSV += '\n';
			configHeadersForCSV += '\n';
		}

		for (let j = 0; j < summaryDataForOpponent[i].length; j++) {
			for (let k = 0; k < columnKeysForCSV[i].length; k++) {
				configDataForCSV += csvHandleQuotes(summaryDataForOpponent[i][j][columnKeysForCSV[i][k]]) + ', ';
			}
			configDataForCSV += '\n';
		}

		configForCSV += configHeadersForCSV + configDataForCSV + '\n';
	}

	statsConfigForLargeReport += configForCSV + "\n";

	$("#exportButton_"+type).click(function () {

		var date = new Date();
		var filename =  headingForCSVReport + '-' + reportName + '-' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + '.csv';
		filename = filename.replace(/ |\//g, '_');

		post_to_url("/solo/export", { filename: filename, data: statsConfigForLargeReport });
	});


	$(document).on("click", ".catCheck_"+type, function () {

		$(this).attr("checked", this.checked);

		var $parent  = $(this).parent();
		var $children = $parent.children();
		$children.find(":checkbox").attr("checked", this.checked);

		var catId = $parent.attr("id");

		var isExists = false;

		if (catId !== "" && catId !== "General") {
			isExists = containsCategoryInResultArray(catId, categoriesInTable);
		}

		var checkedValue = $(this).is(":checked");

		if (checkedValue) {
			if (!isExists && catId !== "" && catId !== "General") {
				categoriesInTable.push({category: catId, table: 0});
			}
		} else {
			categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
		}

		findTableConfigTemplate(categoriesInTable, type);
	});

	$(document).on("click", ".typeCheck_"+type, function () {

		$(this).attr("checked", this.checked);

		var $parent  = $(this).parent();
		var $children = $parent.children();
		$children.find(":checkbox").attr("checked", this.checked);

		var catId = $parent.parent().attr("id");

		if ($(".typeCheck_"+type+"_"+catId+":checked").length === $(".typeCheck_"+type+"_"+catId).length) {
			$parent.parent().find(".catCheck_"+type+"_"+catId).attr("checked", "checked");
		} else {
			$parent.parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");
		}

		var isExists = false;
		if (catId !== "" && catId !== "General") {
			isExists = containsCategoryInResultArray(catId, categoriesInTable);
		}

		var checkedValue = $(this).is(":checked");

		if (checkedValue) {
			if (!isExists) {
				categoriesInTable.push({category: catId, table: 0});
			}
		} else {
			if (!($parent.parent().find(".typeCheck_"+type+"_"+catId).is(":checked")) && !($parent.parent().find(".keyCheck_"+type+"_"+catId).is(":checked"))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			}
		}

		findTableConfigTemplate(categoriesInTable, type);
	});

	$(document).on("click", ".subCheck_"+type, function () {

		$(this).attr("checked", this.checked);

		var $parent  = $(this).parent();
		var $children = $parent.children();
		$children.find(":checkbox").attr("checked", this.checked);

		var catId = $parent.parent().parent().attr("id");

		if ($(".subCheck_"+type+"_"+catId).length === $(".subCheck_"+type+"_"+catId+":checked").length) {
			$parent.parent().find(".typeCheck_"+type+"_"+catId).attr("checked", "checked");
			if ($(".typeCheck_"+type+"_"+catId+":checked").length === $(".typeCheck_"+type+"_"+catId).length) {
				$parent.parent().parent().find(".catCheck_"+type+"_"+catId).attr("checked", "checked");
			}
		} else {
			$parent.parent().find(".typeCheck_"+type+"_"+catId).removeAttr("checked");
			if ($(".typeCheck_"+type+"_"+catId+":checked").length !== $(".typeCheck_"+type+"_"+catId).length) {
				$parent.parent().parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");
			}
		}

		var isExists = false;
		if (catId !== "" && catId !== "General") {
			isExists = containsCategoryInResultArray(catId, categoriesInTable);
		}

		var checkedValue = $(this).is(":checked");

		if (checkedValue) {
			if (!isExists) {
				categoriesInTable.push({category: catId, table: 0});
			}
		} else {
			if (!($parent.parent().parent().find(".subCheck_"+type+"_"+catId).is(":checked")) && !($parent.parent().parent().find(".keyCheck_"+type+"_"+catId).is(":checked"))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			}
		}

		findTableConfigTemplate(categoriesInTable, type);
	});

	$(document).on("click", ".keyCheck_"+type, function () {

		$(this).attr("checked", this.checked);

		var $parent  = $(this).parent();

		var catId = "";
		var typeId = "";
		var subCatId = "";

		if (!($(this).parent().parent().is("div"))) {
			catId = $(this).parent().parent().attr('id');
			typeId = '';
			subCatId = '';
		}
		if (!$(this).parent().parent().parent().is("div")) {
			catId = $(this).parent().parent().parent().attr("id");
			typeId = $(this).parent().parent().attr("id");
			subCatId = "";
		}
		if (!($(this).parent().parent().parent().parent().is("div"))) {
			catId = $(this).parent().parent().parent().parent().attr("id");
			typeId = $(this).parent().parent().parent().attr("id");
			subCatId = $(this).parent().parent().attr("id");
		}

		if (typeId !== "" && ($(".keyCheck_"+type+"_"+catId+"_"+typeId+":checked").length === $(".keyCheck_"+type+"_"+catId+"_"+typeId).length)) {
			$parent.parent().find(".typeCheck_"+type+"_"+catId+"_"+typeId).attr("checked", "checked");
		} else {
			$parent.parent().find(".typeCheck_"+type+"_"+catId+"_"+typeId).removeAttr("checked");
		}

		if (typeId !== "" && subCatId !== "" && ($(".keyCheck_"+type+"_"+catId+"_"+typeId+"_"+subCatId+":checked").length === $(".keyCheck_"+type+"_"+catId+"_"+typeId+"_"+subCatId).length)) {
			$parent.parent().find(".subCheck_"+type+"_"+catId+"_"+subCatId).attr("checked", "checked");
			if ($(".subCheck_"+type+"_"+catId+":checked").length === $(".subCheck_"+type+"_"+catId).length) {
				$parent.parent().parent().find(".typeCheck_"+type+"_"+catId).attr("checked", "checked");
			}
		} else {
			$parent.parent().find(".subCheck_"+type+"_"+catId+"_"+subCatId).removeAttr("checked");
			if ($(".subCheck_"+type+"_"+catId+":checked").length !== $(".subCheck_"+type+"_"+catId).length) {
				$parent.parent().parent().find(".typeCheck_"+type+"_"+catId).removeAttr("checked");
			}
		}

		if ($(".keyCheck_"+type+"_"+catId+":checked").length === $(".keyCheck_"+type+"_"+catId).length) {
			$parent.parent().find(".catCheck_"+type+"_"+catId).attr("checked", "checked");

			if (!($parent.parent().parent().is("div"))) {
				$parent.parent().parent().find(".catCheck_"+type+"_"+catId).attr("checked", "checked");
			}

			if (!($parent.parent().parent().parent().is("div"))) {
				$parent.parent().parent().parent().find(".catCheck_"+type+"_"+catId).attr("checked", "checked");
			}
		} else {
			$parent.parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");

			if (!($parent.parent().parent().is("div"))) {
				$parent.parent().parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");
			}

			if (!($parent.parent().parent().parent().is("div"))) {
				$parent.parent().parent().parent().find(".catCheck_"+type+"_"+catId).removeAttr("checked");
			}
		}

		var isExists = false;
		if (catId !== "" && catId !== "General") {
			isExists = containsCategoryInResultArray(catId, categoriesInTable);
		}

		var checkedValue = $(this).is(":checked");

		if (checkedValue) {
			if (!isExists) {
				if (catId !== "" && catId !== "General") {
					categoriesInTable.push({category: catId, table: 0});
				}
			}
		} else {
			if (!($(this).parent().parent().find('.keyCheck_'+type+"_"+catId).is(':checked'))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			} else if (!($(this).parent().parent().parent().find('.keyCheck_'+type+"_"+catId).is(':checked'))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			} else if (!($(this).parent().parent().parent().parent().find('.keyCheck_'+type+"_"+catId).is(':checked'))) {
				categoriesInTable = removeCategoryFromResultArray(catId, categoriesInTable);
			}
		}

		findTableConfigTemplate(categoriesInTable, type);
	});

	$(document).on("change", "select", function() {
		changeFlag = true;

		var category = $(this).attr("id");
		var option = "";

		$("select option:selected").each(function () {
			option = $(this).val();
		});

		for (let i = 0; i < categoriesInTable.length; i++) {
			if (categoriesInTable[i].category == category) {
				categoriesInTable[i].table = option;
			}
		}
	});

	$(document).on("change", ":checkbox", function() {
		changeFlag = true;
	});

	// clear the previous handler
	$("#save_config_button_"+type).off("click");
	$("#reset_defaults_button_"+type).off("click");

	// attach the new handler
	$("#save_config_button_"+type).click(function () {
		saveUserColumnsHandler(type, changeFlag, reportName);

		if (changeFlag) {
			for (let i = 0; i < listOfGrids.length; i++) {
				if ($(listOfGrids[i]).length) {
					$.jgrid.gridUnload(listOfGrids[i]);
					$(listOfGrids[i]).empty();
				}
			}

			callProcessStatsForType(type);
		}

		cleanUpBootstrapModalClose();
	});

	$("#reset_defaults_button_"+type).click(function () {
		findListTemplate(reportName, type, true);
		let temp = ReportConfig.getTableNumberForCategory(reportName, type, true);
		findTableConfigTemplate(temp, type);

		if (JSON.stringify(temp) !== JSON.stringify(categoriesInTable)) {
			changeFlag = true;
			$.alert("Reset Complete.", "INFO");
			// alert('reset complete');
		} else {
			changeFlag = false;
			$.alert("Nothing to Reset.", "INFO");
			// alert('nothing to reset');
		}

		$("#reset_defaults_button_"+type).css('outline', 0);
	});

	$(document).on('hidden', '#modal_'+type, function(){
		if (!isSaveButtonClicked) {
			findListTemplate(reportName, type);

			categoriesInTable = ReportConfig.getTableNumberForCategory(reportName, type);

			findTableConfigTemplate(categoriesInTable, type);
		}

		isSaveButtonClicked = false;
		isModalVisible = false;
	});

	$(document).on('show', '#modal_'+type, function(){
		$('#table_select_container_'+type).show();
		isModalVisible = true;
	});

	$(document).on('shown', '#modal_'+type, function(){
		$('#modal_table_container_'+type).scrollTop(0);
		isModalVisible = true;
	});

	$(document).on('hide', '#modal_'+type, function(){
		$('#table_select_container_'+type).hide();
		isModalVisible = false;
	});
};

var debouncer = function(func, timeout) {
	let timeoutID;
	timeout = timeout || 2000;
	return function () {
		var scope = this, args = arguments;
		clearTimeout(timeoutID);
		timeoutID = setTimeout(function () {
			func.apply( scope, Array.prototype.slice.call( args ) );
		}, timeout);
	};
};

var setResizeHandler = () => {
	// TODO: figure out what this is for
	if (!CONFIG.IS_MOBILE) {
		$(window).resize(debouncer(function () {
			if (!isModalVisible && !$('#SOLO_tab39').is(':visible')) {
				var processingTime = App.calculateProcessingTimeForTab(webReport.currentTab);
				if (processingTime < CONFIG.DISPLAY_TAB_THRESHOLD) {
					if ($('#SOLO_tab_marker_14_sub_game').hasClass('selected')) {
						App.selectTab(webReport.currentTab, 'game');
					} else if ($('#SOLO_tab_marker_14_sub_match').hasClass('selected')) {
						App.selectTab(webReport.currentTab, 'match');
					} else {
						App.selectTab(webReport.currentTab);
					}
				} else {
					webReport.disableViewport();

					var promise = (function () {
						var deferred = new $.Deferred();

						setTimeout(function() {
							if ($('#SOLO_tab_marker_14_sub_game').hasClass('selected')) {
								App.selectTab(webReport.currentTab, 'game');
							} else if ($('#SOLO_tab_marker_14_sub_match').hasClass('selected')) {
								App.selectTab(webReport.currentTab, 'match');
							} else {
								App.selectTab(webReport.currentTab);
							}
							deferred.resolve();
						}, CONFIG.DISPLAY_TAB_TIMEOUT);
						return deferred.promise();
					})();

					promise.always(function () {
						webReport.enableViewport();
					});
				}
			}
		}));
	}
};

var callProcessStatsForType = function(type) {

	switch (type) {
		case 'byGameStats':
			webReport.processPlayerByGameStats();
			break;

		case 'byMatchStats':
			webReport.processPlayerByMatchStats();
			break;

		case 'byRotPlayerServe':
		case 'byRotPlayerReceive':
			webReport.processRotationByPlayerStats();
			break;

		case 'byPlayer':
		case 'byGame':
		case 'byRotation_1':
		case 'byRotation_2':
			webReport.processSummaryStats();
			break;

		case 'byRotServe':
		case 'byRotReceive':
		case 'byRotAnalyzeServe':
		case 'byRotAnalyzeReceive':
			webReport.processAnalyze();
			break;

		case 'byPlayerRanking':
			webReport.processPlayerRanking();
			break;

		case 'byPlayerEef':
			webReport.processPlayerEefStats();
			break;

		case 'byMatchAgg':
			webReport.processMatchAggregateStats();
			break;

		case 'byGameAgg':
			webReport.processGameAggregateStats();
			break;

		case 'byOurRotationServe':
		case 'byOurRotationReceive':
			webReport.processRotationEefStats();
			break;

		case 'byBoxScores':
			webReport.processBoxScoresStats();
			break;

		case 'byBoxScoresTeam':
			webReport.processBoxScoresStatsForTeam();
			break;
	}
};

// charting
//

var publishChart = function (data, columns, target, title, axes) {

	var inRally = { pass: false, hit: false, dig: false };
	var statsComplete = Stats.calc(data.byRow, columns, { inRally: inRally });

	var chartTarget = target + " .chart";
	var chartLegend = target + " .legend";
	//$("#" + target).empty();
	$("#" + chartTarget).empty();
	$("#" + chartLegend).empty();

	var COLORS = [
		"#c5b47f",
		"#4bb2c5",
		"#EAA228",
		"#579575",
		"#839557",
		"#958c12",
		"#953579",
		"#4b5de4",
		"#d8b83f",
		"#ff5800",
		"#0085cc"
	];

	var MARKERS = [
		"circle",
		"square",
		"filledCircle",
		"diamond"
	];

	// initialize line array
	var lines = [];
	var series = [];
	for (let i=0, iLen=axes.y.length; i < iLen; i++) {
		lines[i] = [];
		series[i] = {
			color: COLORS[i],
			markerOptions:{style: MARKERS[i]}
		};
	}

	// ensure that identical x axes don't overlap
	var xAxes = {};
	for (let i=0, iLen=statsComplete.length; i < iLen; i++) {

		// TODO: handle for multiple
		var xKey = axes.x[0];
		var x = statsComplete[i][xKey];

		// ensure that identical x axes don't overlap
		if (xAxes[x]) {
			xAxes[x]++;
			x = x + ' #' + xAxes[x];
		} else {
			xAxes[x] = 1;
		}
		//var matchName = statsComplete[i]['match_name'];

		for (let j=0, jLen=axes.y.length; j < jLen; j++) {
			var yKey = axes.y[j];
			// TODO: handle for decimals
			var y = parseInt(statsComplete[i][yKey], 10);
			lines[j].push([x, y]);
		}
	}

	$.jqplot(chartTarget, lines, {
		title: title,
		series: series,
		axesDefaults: {
			tickRenderer: $.jqplot.CanvasAxisTickRenderer,
			tickOptions: {
				angle: -45,
				fontSize: '10pt'
			}
		},
		axes: {
			xaxis: {
				renderer: $.jqplot.CategoryAxisRenderer
			}
		}
	});

	// legend
	let legendHtml = '';
	for (let i=0, iLen=axes.y.length; i < iLen; i++) {
		let tr = '<tr>';
		tr += '<td class="legend_color legend_color_' + i + '"></td>';
		tr += '<td class="legend_text">' + getCodeTranslation(axes.y[i]) + '</td>';
		tr += '</tr>';
		legendHtml += tr;
	}
	legendHtml = $('<table />').html(legendHtml);
	$("#" + chartLegend).append(legendHtml);

};

var analyzeDrillDown = function (rotation_num, is_serving) {

	var games = webReport.getSelectedGamesAndMatches().games;
	var players = webReport.getPlayerNumsByTeam(webReport.currentTeam);
	var playerNames = webReport.getPlayerNumNameMapping(webReport.currentTeam);

	var rot_player_list = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };

	var usthem = is_serving ? 'us' : 'them';

	for (let i=0, iLen=players.length; i < iLen; i++) {
		for (let j=1; j <= 6; j++) {
			rot_player_list[j].push(j + ':' + usthem + ':' + players[i]);
		}
	}

	var template_values = {
		rotation_number: translateRotation(rotation_num),
		serving_or_receiving: is_serving ? 'Serving' : 'Receiving'
	};

	var processed_stats = StatPrep.processStatsIteration(webReport.data, { type: 'rot_serv_player', rotation: rotation_num, serving: usthem, list: rot_player_list[rotation_num], listNames: playerNames }, { type: 'game', list: games } );

	var labels = '';

	if (is_serving) {
		labels = 'byRotAnalyzeServe';
	} else {
		labels = 'byRotAnalyzeReceive';
	}

	var settings  = {
		caption: "Rotation " + template_values.rotation_number + " " + template_values.serving_or_receiving,
		hidegrid: false,
		footerrow : true,
		userDataOnFooter : true,
		hoverrows: false
	};

	//Adjust playerNums to playerJerseys for display
	webReport.convertPlayerStatstoJerseys(processed_stats);

	publishReport(labels, processed_stats, [], settings);
};

var getColumnsFromTemplate = function (template) {
	var columns = [];

	$(template).find(".has_data_keys th").each(function () {
		var key = $(this).attr("data-key");
		try {
			if (key.charAt(0) === '{') {
				var json_key = JSON.parse(key);
				columns.push(json_key);
			} else {
				columns.push(key);
			}
		} catch {
			App.debugLog("Error parsing: " + key);
		}
	});

	return columns;

};

var hiliteStatisticalOutliers = function (table) {
	var rowNumbers = $(table).jqGrid('getDataIDs');

	var colModel = $(table).jqGrid('getGridParam', 'colModel');

	var allColumnsData = [];
	var allColumnsIndex = [];
	for (let i = 0; i < colModel.length; i++) {
		var gridData = $(table).jqGrid('getCol', colModel[i].index);
		allColumnsIndex.push(colModel[i].index);
		allColumnsData.push(gridData);
	}

	for (let i = 0; i < allColumnsData.length; i++) {
		let rows = allColumnsData[i];
		let row_vals = [];

		let total = 0;
		for (let j = 0; j < rows.length; j++) {
			let val = parseInt(rows[j], 10);

			total += val;
			row_vals[j] = val;
		}

		// Note: this only works where every row has a valid value.  If we start to
		// look at Stats.NOT_AVAILABLE stats, this will need to be modified.
		let mean = total / rows.length;
		let totalDiffFromMean = 0;

		// Determine our standard deviation
		for (let j=0, jlen=row_vals.length; j < jlen; j++) {
			let val = row_vals[j];
			totalDiffFromMean += Math.pow((val - mean), 2);
		}

		let populationStdDev = Math.sqrt(totalDiffFromMean / rows.length);

		// now apply hiliting as appropriate
		for (let j=0, jlen=row_vals.length; j < jlen; j++) {
			// two standard deviations
			if (Math.abs(row_vals[j] - mean) > populationStdDev) {
				if (Math.abs(row_vals[j] - mean) > (2 * populationStdDev)) {
					$(table).jqGrid('setCell', rowNumbers[j], allColumnsIndex[i], '', {'background-color': CONFIG.HILITE_TWO_STD_DEV});
				} else {
					$(table).jqGrid('setCell', rowNumbers[j], allColumnsIndex[i], '', {'background-color': CONFIG.HILITE_ONE_STD_DEV});
				}
			}
		}
	}
};

var post_to_url = function (path, params, method) {
	method = method || "post"; // Set method to post by default, if not specified.

	// The rest of this code assumes you are not using a library.
	// It can be made less wordy if you use one.
	var form = document.createElement("form");
	form.setAttribute("method", method);
	form.setAttribute("action", path);

	for (let key in params) {
		if (params.hasOwnProperty(key)) {
			var hiddenField = document.createElement("input");
			hiddenField.setAttribute("type", "hidden");
			hiddenField.setAttribute("name", key);
			hiddenField.setAttribute("value", params[key]);

			form.appendChild(hiddenField);
		}
	}

	document.body.appendChild(form);
	form.submit();
};


var translateRotation = function (rotation) {
	var intlRotationNumbering = { 1: 1, 2: 6, 3: 5, 4: 4, 5: 3, 6: 2 };
	var rnc = $("#SOLO_settings_rot_num_conv input[type=radio]:checked").val();
	if (rnc === "intl") {
		return intlRotationNumbering[rotation];
	} else {
		return rotation;
	}
};

var saveUserColumnsHandler = function (type, changeFlag, reportName) {

	// TODO: Does this need to be global?
	isSaveButtonClicked = true;

	var list = $("#list_data_container_" + type).attr("id");

	var genArray = [];

	var obj = {
		general: [],
		tables: []
	};

	var tableSelectedObject = {};
	var tablesNumArray = [];
	var tablesArray = [];
	var val = "";

	var config_table_select = $("#table_select_container_" + type).find("select");

	$(config_table_select.find("option:selected")).each(function () {
		var attId = $(this).parent().attr("id");
		val = $(this).val();
		tableSelectedObject[attId] = val;
		if ($.inArray(val, tablesNumArray) === -1 && val != "0") {
			tablesNumArray.push(val);
			tablesArray.push([]);
		}
	});

	if (val == "0") {
		$.alert("You added stats that must be assigned to a table group. Please try again and select table group for new stats.", "INFO");
		// alert("Please select appropriate table");
		isSaveButtonClicked = false;
		return false;
	}

	var sortArray = function(valueA, valueB) {
		return (valueA - valueB);
	};

	tablesNumArray.sort(sortArray);

	$("#"+list+" .keyCheck_"+type).each(function() {
		var colId = $(this).parent().attr("id");
		var catId = "";

		if (!($(this).parent().parent().is('div'))) {
			catId = $(this).parent().parent().attr('id');
		}
		if (!($(this).parent().parent().parent().is('div'))) {
			catId = $(this).parent().parent().parent().attr('id');
		}
		if (!($(this).parent().parent().parent().parent().is('div'))) {
			catId = $(this).parent().parent().parent().parent().attr('id');
		}

		if ($(this).is(":checked")) {
			if (catId === "General") {
				genArray.push(colId);
				obj.general = genArray;
			} else {
				for (let i = 0; i < tablesNumArray.length; i++) {
					if (tableSelectedObject[catId] == tablesNumArray[i]) {
						tablesArray[i].push(colId);
						break;
					}
				}
			}
		}
	});

	for (let i = 0; i < tablesArray.length; i++) {
		obj.tables.push(tablesArray[i]);
	}

	if (changeFlag) {
		ReportConfig.saveUserColumns(reportName, obj);
	}
};

// HACK: Bootstrap isn't removing the "modal-open" class from body after
// the modal closes, which is preventing scrolling.
//
// http://stackoverflow.com/questions/24296643/modal-closes-but-scroll-bar-doesnt-come-back
var cleanUpBootstrapModalClose = function () {
	$("body").removeClass("modal-open");
};
