
export { Team, Match };

function Team(id, name, gender, level, org, tournaments) {

	// ---------------------------
	// Properties
	// ---------------------------

	this.id          = id;
	this.name        = (name !== undefined)   ? name   : "";
	this.gender      = (gender !== undefined) ? gender : "";
	this.level       = (level !== undefined)  ? level  : "";
	this.org         = (org !== undefined)    ? org    : "";
	this.players     = [];
	this.tournaments = (tournaments !== undefined) ? tournaments : [];
}


function Match(id, team_id, opponent, venue, date) {

	// ---------------------------
	// Properties
	// ---------------------------

	this.id       = id;
	this.team_id  = team_id;
	this.opponent = (opponent !== undefined) ? opponent : "";
	this.venue    = (venue !== undefined)    ? venue    : "";
	this.date     = (date !== undefined)     ? date     : "";
	this.players  = [];

}
