
export { wrGraph };

import _ from 'lodash';
import google from 'google';


var wrGraph = (function(){

	// references to modules
	var webReport;
	var Columns;
	var ReportConfig;

	var currentReport;
	var graphDialog;
	var selStats = [];
	var defaultGraphStats = {
		serve: ['serve_rating', 'pt_score_percent', 'serve_percent', 'serve_error_percent', 'ace_percent'],
		receive: ['pass_rating', '3pass_percent', 'pass_error_percent'],
		attack: ['hitting_efficiency', 'combined_kill_percent', 'combined_kill_error_percent'],
		dig: ['dig_percent', 'dig_error_percent'],
		block: ['block_percent', 'block_error_percent'],
		fbp: ['fbp_rating', '3fbp_percent', 'fbp_error_percent']
	};
	var teamRatingColor = '#77ccdd';
	var playerRatingColor = '#22ee88';
	var ratingColors = [];
	var seriesGoodColors = ['#0066ff', '#cc00cc', '#00aa11', '#00cccc', '#9966ff', '#cc33ff', '#ff00ff', '#00bb88'];
	var seriesBadColors = ['#ff0000', '#ff8000', '#ffcc00', '#cc0000', '#ff6699', '#cc6600', '#996600', '#cc9900'];
	var userSetStats = {
		serve: [],
		receive: [],
		attack: [],
		dig: [],
		block: [],
		fbp: []
	};
	var graphIsDrawing = false;

	var init = function(opts) {

		webReport = opts.webReport;
		Columns = opts.Columns;
		ReportConfig = opts.ReportConfig;

		graphDialog = $("#wrgraph-dialog").dialog({
			height: 680,
			width: 780,
			modal: true,
			resizable: false,
			autoOpen: false,
			title: 'WebReports Graphs',
			open: function(){
				$("body").css("overflow", "hidden");
			},
			close: function(){
				$("body").css("overflow", "auto");
				$(this).dialog('close');
				$("#wrgraph-dialog-target").empty();
			}
		});
		if (typeof google !== 'undefined'){
			google.charts.load('current', {'packages':['corechart']});
		}
		$('#wrgraph-select-category select').on('change', setCategory);
	};

	var getReportType = function(){
		var reportType;
		switch (webReport.currentTab){
			case 34:
				reportType = 'summary_by_tournament';
				break;
			case 35:
				reportType = 'tournament_comparison_by_player';
				break;
			case 37:
				reportType = 'summary_by_match';
				break;
			case 38:
				reportType = 'match_comparison_by_player';
				break;
			// add other tabs here to enable graphing on that tab
		}
		return reportType;
	};

	var filterCategoryData = function(columns, categoryNum, reportType){
		var categoryData = columns.filter(function(x){ return x.key_name === categoryNum; })[0];
		var activeColumns = ReportConfig.getColumnsForConfig(reportType);
		var filteredColumns = [];
		var col;
		var modifyLabel = function(inputCol){
			// Modify label to fit better in graph legend
			inputCol.label = inputCol.label.replace('Percent', '%');
			inputCol.label = inputCol.label.replace('Total ', '');
			return inputCol;
		};
		for (let i=0; i < categoryData.typeArray.length; i++){
			if (categoryData.typeArray[i].label === 'Percent'){
				for (let j=0; j < categoryData.typeArray[i].keyArray.length; j++){
					if (activeColumns[categoryData.typeArray[i].keyArray[j].key_name]){
						col = modifyLabel(categoryData.typeArray[i].keyArray[j]);
						filteredColumns.push(col);
					}
				}
			}
		}
		return filteredColumns;
	};

	var showGraphDialog = function(){
		graphDialog.dialog('open');
		graphDialog.dialog({position: {my: 'center', at: 'center', of: window} });
		setCategory();
	};

	var closeGraphDialog = function(){
		graphDialog.dialog('close');
	};

	var setCategory = function(cat, rptType, noDraw){
		var selCategory = $('#wrgraph-select-category select').val();
		if (cat && typeof cat === 'string'){
			selCategory = cat;
		}
		var categoryNum = null;
		var reportType;
		if (rptType){
			reportType = rptType;
		} else {
			reportType = getReportType();
		}
		switch (selCategory){
			case 'serve':
				categoryNum = Columns.CAT_SERVE;
				break;
			case 'receive':
				categoryNum = Columns.CAT_RECEIVE;
				break;
			case 'attack':
				categoryNum = Columns.CAT_ATTACK;
				break;
			case 'dig':
				categoryNum = Columns.CAT_DIG;
				break;
			case 'block':
				categoryNum = Columns.CAT_BLOCK;
				break;
			case 'fbp':
				categoryNum = Columns.CAT_FBPASS;
				break;
		}

		var filteredColumns = filterCategoryData(Columns.getAllForConfigUi(reportType), categoryNum, reportType);
		if (userSetStats[selCategory].length){
			setSelStats(userSetStats[selCategory], selCategory);
		} else {
			setSelStats(defaultGraphStats[selCategory], selCategory);
		}
		populateStatSelector(filteredColumns);
		if (!noDraw){
			drawGraph(filteredColumns, reportType);
		}
	};

	var populateStatSelector = function(filteredColumns){
		var target = $('#wrgraph-select-stats-buttons');
		var btn;
		target.empty();
		for (let i=0; i < filteredColumns.length; i++){
			btn = $('<div class="select-stat-button" data-key="' + filteredColumns[i].key_name + '">' + filteredColumns[i].label + '</div>').click(selectStat).appendTo(target);
			if (selStats.includes(filteredColumns[i].key_name)){
				btn.addClass('selected');
			}
		}
	};

	var selectStat = function(e){
		var statKey = e.target.getAttribute('data-key');
		var selCategory = $('#wrgraph-select-category select').val();
		if (selStats.includes(statKey)){
			if (selStats.length === 1){ return; }   // minimum of 1 selected stat
			setSelStats(selStats.filter(function(s){ return s !== statKey; }), selCategory);
		} else {
			selStats.push(statKey);
			setSelStats(selStats, selCategory);
		}
		setCategory();
	};

	var setSelStats = function(stats, category){
		selStats = stats;
		userSetStats[category] = stats;
	};

	var activateDownloadLink = function(chart){
		$('#wrgraph-dialog-dl-link').off('click').click(function(e){
			e.stopPropagation();
			var reportType = getReportType();
			var skill = $('#wrgraph-select-category select').val();
			var player = '';
			if (reportType === 'tournament_comparison_by_player'){
				player = $('#select-tournament-comparison-player option:selected').text().replace(/\s+/g, '') + '_';
			} else if (reportType === 'match_comparison_by_player'){
				player = $('#select-match-comparison-player option:selected').text().replace(/\s+/g, '') + '_';
			}
			var anchor = document.createElement('a');
			anchor.download = 'WebReports_' + reportType.replace(/_/g, '-') + '_' + player + skill + '_' + (new Date().toISOString().substring(0, 10)) + '.png';
			anchor.href = chart.getImageURI();
			anchor.target = '_blank';
			anchor.click();
		});
	};

	var drawGraph = function(filteredColumns, reportType, exportStats, graphTitle){
		if (typeof google === 'undefined'){
			alert('Google Charts API could not be reached. Please reload the page and try again later.');
			graphDialog.dialog('close');
			return false;
		}
		currentReport = wrGraph.currentReport;
		if (exportStats){
			currentReport = { 'byRow': exportStats, 'agg': null };
		}
		var dataArray = [];
		var graphColumns = [];
		var parseStatVal = function(input){
			// change stat from string to float usable by grapher
			if (!input){
				return null;
			}
			if (input === '-'){
				return 0;
			}
			if (input.indexOf('%') > -1){
				return (parseFloat(input) / 100);
			}
			return parseFloat(input);
		};
		var getStatFromCurrentReport = function(statGroupingItemId, col, statReportType){
			var statsByRow = currentReport.byRow;
			if (statReportType === 'summary_by_tournament'){
				for (let i=0; i < statsByRow.length; i++){
					for (let j=0; j < statsByRow[i].length; j++){
						if (
							statsByRow[i][j].hasOwnProperty(col.stat)
							&& String(statsByRow[i][j].tournament_id) === String(statGroupingItemId)
						){
							return statsByRow[i][j][col.stat];
						}
					}
				}
			} else if (statReportType === 'tournament_comparison_by_player'){
				for (let i=0; i < statsByRow.length; i++){
					for (let j=0; j < statsByRow[i].length; j++){
						if (
							statsByRow[i][j].hasOwnProperty(col.stat)
							&& String(statsByRow[i][j].tournament_id) === String(statGroupingItemId)
						){
							if (
								(
									(col.statType === 'player' || col.statType === 'stat_grouping_name')
									&& statsByRow[i][j].player_name !== 'TEAM'
								)
								|| (col.statType === 'team' && statsByRow[i][j].player_name === 'TEAM')
							) {
								return statsByRow[i][j][col.stat];
							}
						}
					}
				}
			} else if (statReportType === 'summary_by_match'){
				for (let i=0; i < statsByRow.length; i++){
					for (let j=0; j < statsByRow[i].length; j++){
						if (statsByRow[i][j].hasOwnProperty(col.stat) && String(statsByRow[i][j].match_id) === String(statGroupingItemId)){
							return statsByRow[i][j][col.stat];
						}
					}
				}
			} else if (statReportType === 'match_comparison_by_player'){
				for (let i=0; i < statsByRow.length; i++){
					for (let j=0; j < statsByRow[i].length; j++){
						if (statsByRow[i][j].hasOwnProperty(col.stat) && String(statsByRow[i][j].match_id) === String(statGroupingItemId)){
							if (((col.statType === 'player' || col.statType === 'stat_grouping_name') && statsByRow[i][j].player_name !== 'TEAM') || (col.statType === 'team' && statsByRow[i][j].player_name === 'TEAM')) {
								return statsByRow[i][j][col.stat];
							}
						}
					}
				}
			}
		};
		var getNextColor = function(series, colorArray){
			// get first item from colorArray that's not already in series
			var usedColors = [];
			for (let i in series){
				usedColors.push(series[i].color);
			}
			for (let j=0; j < colorArray.length; j++){
				if (!usedColors.includes(colorArray[j])){
					return colorArray[j];
				}
				if (reportType === 'tournament_comparison_by_player' || reportType === 'match_comparison_by_player'){
					// if color found in usedColors, check number of occurrences;
					// return color again if only 1 (allows dotted/solid lines of same color)
					if (_.countBy(usedColors)[colorArray[j]] && _.countBy(usedColors)[colorArray[j]] < 2){
						return colorArray[j];
					}
				}
			}
			return null;
		};

		var data = google.visualization.arrayToDataTable(dataArray);

		var statGrouping;
		if (reportType.indexOf('tournament') >= 0){
			statGrouping = 'tournament';
		} else if (reportType.indexOf('match') >= 0){
			statGrouping = 'match';
		} else {
			throw new Error('Unrecognized stat grouping');
		}

		// add column titles
		data.addColumn('string', statGrouping.charAt(0).toUpperCase() + statGrouping.slice(1));
		graphColumns.push({stat: statGrouping + '_name', statType:'stat_grouping_name'});
		for (let i=0; i < filteredColumns.length; i++){
			if (selStats.includes(filteredColumns[i].key_name)){
				if (reportType === 'tournament_comparison_by_player' || reportType === 'match_comparison_by_player'){
					data.addColumn('number', 'Player ' + filteredColumns[i].label);
					graphColumns.push({stat: filteredColumns[i].key_name, statType:'player'});
					data.addColumn('number', 'Team ' + filteredColumns[i].label);
					graphColumns.push({stat: filteredColumns[i].key_name, statType:'team'});
				} else {
					data.addColumn('number', filteredColumns[i].label);
					graphColumns.push({stat: filteredColumns[i].key_name, statType:'team'});
				}
			}
		}
		// get data per statGrouping (tournament or match)
		var tmpRow = [];
		var selStatGroupingItems;
		if (statGrouping === 'tournament'){
			selStatGroupingItems = webReport.getSelectedTournaments();
		} else if (statGrouping === 'match'){
			selStatGroupingItems = webReport.getSelectedMatchesForTrendReport();
		}
		for (let i=0; i < selStatGroupingItems.length; i++){
			tmpRow = [];
			for (let j=0; j < graphColumns.length; j++){
				if (j===0){
					// don't attempt to parse tournament/match name
					tmpRow.push(getStatFromCurrentReport(selStatGroupingItems[i], graphColumns[j], reportType));
				} else {
					tmpRow.push(parseStatVal(getStatFromCurrentReport(selStatGroupingItems[i], graphColumns[j], reportType)));
				}
			}
			data.addRow(tmpRow);
		}

		var series = {};
		var lineColor = null;
		var colorSource = null;
		// display rating stats as bars and non-rating stats as lines
		for (let i=0; i < graphColumns.length; i++){
			lineColor = null;
			colorSource = null;
			if ((graphColumns[i].stat.indexOf('tournament_name') === -1 && graphColumns[i].stat.indexOf('match_name') === -1) && graphColumns[i].stat.indexOf('rating') === -1){
				series[i - 1] = {type: 'line', targetAxisIndex: 1};
				if (graphColumns[i].stat.indexOf('error') > -1){
					colorSource = seriesBadColors;
				} else {
					colorSource = seriesGoodColors;
				}
				lineColor = getNextColor(series, colorSource);
				if (lineColor){
					series[i - 1].color = lineColor;
				}
				/* jshint -W018 */
				if ((reportType === 'tournament_comparison_by_player' || reportType === 'match_comparison_by_player') && !(i % 2)){
					// if i is odd (i.e., team stat), make line dotted
					series[i - 1].lineDashStyle = [6, 3];
				}
				/* jshint +W018 */
			}
		}

		if (reportType === 'tournament_comparison_by_player' || reportType === 'match_comparison_by_player'){
			ratingColors = [playerRatingColor, teamRatingColor];
		} else {
			ratingColors = [teamRatingColor];
		}

		var hAxisFontSize = 13;
		if (selStatGroupingItems.length > 12){
			hAxisFontSize = 12;
			if (selStatGroupingItems.length > 20){
				hAxisFontSize = 10;
			}
		}

		if (!graphTitle){
			graphTitle = $('#wrgraph-select-category option:selected').text();
		}
		var options = {
			title : graphTitle,
			vAxes: {
				0: {title: 'Rating', ticks: [0, 0.5, 1, 1.5, 2, 2.5]},
				1: {title: 'Percentage', format: '##.#%', ticks: [0, 0.2, 0.4, 0.6, 0.8, 1]}
			},
			hAxis: {textStyle: {fontSize: hAxisFontSize}},
			legend: {position: 'top', maxLines: 4, textStyle: { fontSize: 12, italic: true } },
			chartArea: {width: "80%"},
			colors: ratingColors,
			seriesType: 'bars',
			lineWidth: 3,
			series: series
		};
		var chart = new google.visualization.ComboChart(document.getElementById('wrgraph-dialog-target'));
		graphIsDrawing = true;
		google.visualization.events.addListener(chart, 'ready', graphDone);
		chart.draw(data, options);

		activateDownloadLink(chart);

		return chart;
	};

	var graphDone = function(){
		graphIsDrawing = false;
	};

	var drawGraphForExport = async function(filteredCols, reportType, byRow, graphTitle){
		// We have to wait for the graphs to draw before we can get the image data, so we're
		// waiting for the chart's 'ready' event to set graphIsDrawing=false before continuing.
		let chart = drawGraph(filteredCols, reportType, byRow, graphTitle);
		let waitForDraw = new Promise((resolve, reject) => {
			var tries = 0;
			var intervalId = setInterval(()=>{
				tries++;
				if (tries > 10){
					clearInterval(intervalId);
					return reject('graph draw took too long');
				} else if (graphIsDrawing === false){
					clearInterval(intervalId);
					return resolve(chart);
				}
			}, 100);
		});
		return await waitForDraw;
	};

	var getImageBlobs = async function(stats, mode){
		const categories = {
			'serve': Columns.CAT_SERVE,
			'receive': Columns.CAT_RECEIVE,
			'attack': Columns.CAT_ATTACK,
			'dig': Columns.CAT_DIG,
			'block': Columns.CAT_BLOCK,
			'fbp': Columns.CAT_FBPASS
		};
		const catTitleStrings = {
			'serve': 'Serve',
			'receive': 'Receive',
			'attack': 'Attack',
			'dig': 'Dig',
			'block': 'Block',
			'fbp': 'Free Ball Pass'
		};
		var reportTypes = [];
		var blobs = {};
		if (mode === 'match'){
			reportTypes = [
				'summary_by_match',
				'match_comparison_by_player'
			];
			blobs = {
				'summary_by_match': {
					'serve': null,
					'receive': null,
					'attack': null,
					'dig': null,
					'block': null,
					'fbp': null
				},
				'match_comparison_by_player': {
					'serve': null,
					'receive': null,
					'attack': null,
					'dig': null,
					'block': null,
					'fbp': null
				}
			};
		} else if (mode === 'tournament'){
			reportTypes = [
				'summary_by_tournament',
				'tournament_comparison_by_player'
			];
			blobs = {
				'summary_by_tournament': {
					'serve': null,
					'receive': null,
					'attack': null,
					'dig': null,
					'block': null,
					'fbp': null
				},
				'tournament_comparison_by_player': {
					'serve': null,
					'receive': null,
					'attack': null,
					'dig': null,
					'block': null,
					'fbp': null
				}
			};
		}

		graphDialog.dialog('open');
		// This positions the dialog offscreen while graph images are being generated.
		// Doing this because the dialog must be open/"visible" for images to generate properly.
		graphDialog.dialog({position: {my: 'right', at: 'left', of:document} });
		for (let i=0; i < reportTypes.length; i++){
			for (let c in categories){
				setCategory(c, reportTypes[i], true);
				let filteredCols = filterCategoryData(Columns.getAllForConfigUi(reportTypes[i]), categories[c], reportTypes[i]);
				let chart = await drawGraphForExport(filteredCols, reportTypes[i], stats, catTitleStrings[c]);
				blobs[reportTypes[i]][c] = await (await fetch(chart.getImageURI())).blob();
			}
		}
		graphDialog.dialog('close');
		return blobs;
	};


	return {
		init : init,
		graphDialog : graphDialog,
		showGraphDialog : showGraphDialog,
		closeGraphDialog : closeGraphDialog,
		currentReport : currentReport,
		setCategory : setCategory,
		selectStat : selectStat,
		getImageBlobs : getImageBlobs,
	};

})();
