
// exports: parseGameLog, parseLogRow, editData, parseDateTime

/** NOTE: Removes invalid entries from a game's log */
export function parseGameLog(game, checkValid=true, logInvalid=false) {

	var log     = game.log;
	var invalidGameStats = [];

	/**
	 * A rally action is invalid if:
	 * - it is not in the same rotation as its exit stat.
	 * - it is followed by a pass
	 */
	var isRallyActionValid = function (rally, remaining) {
		var current = rally.rotation;

		var isPass = false;
		if (
			rally.code === '0pass' ||
			rally.code === '1pass' ||
			rally.code === '2pass' ||
			rally.code === '3pass'
		) {
			isPass = true;
		}

		for (let i=0, ilen=remaining.length; i < ilen; i++) {
			if (remaining[i].log_type === "entry") {
				if (remaining[i].action === "in_rally") {
					// ensure it's not a pass
					if (
						remaining[i].code === "0pass" ||
						remaining[i].code === "1pass" ||
						remaining[i].code === "2pass" ||
						remaining[i].code === "3pass"
					) {
						return false;
					}
				} else {

					if (current === remaining[i].rotation) {

						// if we're verifying a passing stat, the exit stat cannot
						// be either:
						// - a serve error
						// - a receive error
						if (isPass && remaining[i].action === "errors") {
							if (remaining[i].code === "rcv") {
								return false;
							}
							if (remaining[i].code === "srv") {
								return false;
							}
						}

						return true;
					} else {
						return false;
					}
				}
			}
		}

		// no exit stat followed, so this is invalid
		return false;
	};

	let data = [];

	for (let i=0, ilen=log.length; i < ilen; i++) {

		// TODO: When we add versioning, we will first have to get the LogAdjustment that
		// tells us what version we're on, and then pass that into parseLogRow
		let row = parseLogRow(log[i]);
		if (row) {
			// Our expected data is inconsistently formatted
			row.team_id  = game.teamId || +game.team_id;
			row.match_id = game.matchId || +game.match_id;
			row.game_num = game.gameNum || +game.num;
			row.game_id  = game.id;

			data.push(row);
		}
	}

	let data2 = [];
	for (let i=0, ilen=data.length; i < ilen; i++) {
		let row = data[i];
		if (row.action === "in_rally") {
			if (checkValid) {
				if (!isRallyActionValid(row, data.slice(i + 1, ilen))) {
					if (logInvalid) {
						invalidGameStats.push(row);
					}
					continue;
				}
			}
		}
		data2.push(row);
	}

	if (checkValid && logInvalid) {
		return { data: data2, invalid: invalidGameStats };
	} else {
		return data2;
	}
}

export const editData = {

	getHitAttemptsForPlayer: function (game, player) {
		var playerNum = parseInt(player.num, 10);

		var edit = game.edit;

		if (typeof edit === 'object' && edit.htatt) {

			var list = edit.htatt;
			for (let i=0, ilen=list.length; i < ilen; i++) {
				var player_num = list[i].player_num;
				var count      = list[i].count;
				if (player_num == playerNum) {
					return count;
				}

			}
			return '';
		}
	},

	getDigAttemptsForPlayer: function (game, player) {
		var playerNum = parseInt(player.num, 10);

		var edit = game.edit;

		if (typeof edit === 'object' && edit.dgatt) {

			var list = edit.dgatt;
			for (let i=0, ilen=list.length; i < ilen; i++) {
				var player_num = list[i].player_num;
				var count      = list[i].count;
				if (player_num == playerNum) {
					return count;
				}

			}
			return "";
		}
	},

	getBlockAttemptsForPlayer: function (game, player) {
		var playerNum = parseInt(player.num, 10);

		var edit = game.edit;

		if (typeof edit === "object" && edit.blatt) {

			var list = edit.blatt;
			for (let i=0, ilen=list.length; i < ilen; i++) {
				var player_num = list[i].player_num;
				var count      = list[i].count;
				if (player_num == playerNum) {
					return count;
				}
			}
			return "";
		}
	},

	hasManualEntriesForGame: function (game) {

		if (!game || !game.edit) {
			return;
		}

		var edit = game.edit;

		if (typeof edit === "object") {
			if (edit.htatt) {
				for (let i=0, ilen=edit.htatt.length; i < ilen; i++) {
					let count = edit.htatt[i].count;
					if (count) {
						return true;
					}
				}
			}
			if (edit.dgatt) {
				for (let i=0, ilen=edit.dgatt.length; i < ilen; i++) {
					let count = edit.dgatt[i].count;

					if (count) {
						return true;
					}
				}
			}
			if (edit.blatt) {
				for (let i=0, ilen=edit.blatt.length; i < ilen; i++) {
					let count = edit.blatt[i].count;

					if (count) {
						return true;
					}
				}
			}
		}
		return false;
	},

	getManualEntriesForGame: function (game) {

		if (!game || !game.edit) {
			return;
		}

		var edit = game.edit;
		var result = {};
		if (typeof edit === 'object') {
			if (edit.htatt) {
				result.htatt = edit.htatt;
			}
			if (edit.dgatt) {
				result.dgatt = edit.dgatt;
			}
			if (edit.blatt) {
				result.blatt = edit.blatt;
			}
		}
		return result;
	}
};

export function parseDateTime(dateTimeString) {
	var MONTHS_TO_ORD = {
		'Jan': '01',
		'Feb': '02',
		'Mar': '03',
		'Apr': '04',
		'May': '05',
		'Jun': '06',
		'Jul': '07',
		'Aug': '08',
		'Sep': '09',
		'Oct': '10',
		'Nov': '11',
		'Dec': '12'
	};

	var ORD_TO_MONTHS = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	var dateTimeRegex = /^(\w+) (\w+) (\d+) (\d+) (\d+):(\d+):(\d+)/g;
	var match = dateTimeRegex.exec(dateTimeString);
	var parsed = {};

	if (match) {
		parsed = {
			'month'    : match[2],
			'month_ord': MONTHS_TO_ORD[match[2]],
			'mday'     : match[3],
			'year'     : match[4],
			'hour'     : match[5],
			'minute'   : match[6],
			'second'   : match[7]
		};
	} else {
		// 2012-01-26T22:50:24.986Z
		var dateTimeRegex2 = /^(\d{4})-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)/g;
		var match2 = dateTimeRegex2.exec(dateTimeString);

		if (match2) {
			parsed = {
				'month'    : ORD_TO_MONTHS[parseInt(match2[2], 10)],
				'month_ord': match2[2],
				'mday'     : match2[3],
				'year'     : match2[1],
				'hour'     : match2[4],
				'minute'   : match2[5],
				'second'   : match2[6]
			};
		} else {
			//alert(CONFIG.ERROR_500);
			//console.log("NO MATCH FOR DATE FORMAT: " + dateTimeString);

			// Doing the best we can with a bad date
			parsed = {
				'month'    : ORD_TO_MONTHS[parseInt(1, 10)],
				'month_ord': 1,
				'mday'     : 1,
				'year'     : 2000,
				'hour'     : 0,
				'minute'   : 0,
				'second'   : 0,
			};
		}
	}

	return parsed;
}

export function parseLogRow(logRow) {

	let row = null;

	// ["LogAdjustment","Fri Aug 17 2012 17:40:35 GMT-0500 (CDT)","Score & Serving Adjustment",0,-1,"them",2,"them"],
	if (logRow[0] === 'LogAdjustment') {
		row = {
			log_type:    'adjustment',
			date:        logRow[1],
			desc:        logRow[2],
			us_delta:    logRow[3],
			them_delta:  logRow[4],
			serving:     logRow[5],
			rotation:    logRow[6],
			point:       logRow[7],
		};

	} else if (logRow[0] === 'LogTimeout') {
		row = {
			log_type:    'timeout',
			date:        logRow[1],
			who:         logRow[2],
			number:      logRow[3],
			serving:     logRow[4],
			rotation:    logRow[5],
			point:       logRow[6],
		};

	} else if (logRow[0] === 'LogSubstitution') {
		row = {
			log_type:    'substitution',
			date:        logRow[1],
			subbed_in:   logRow[2],
			subbed_out:  logRow[3],
			libero:      logRow[4],
			subs_used:   logRow[5],
			lineup:      logRow[6],
			serving:     logRow[7],
			rotation:    logRow[8],
			point:       logRow[9],
		};

	} else if (logRow[0] === 'LogEntry') {

		var rotation = logRow[2];
		var serving  = logRow[3];

		// not using this for anything yet
		//if (logRow.length < 15) {
		//	var version = '1.0'; // anything pre 2.5 essentially
		//} else {
		//	var version = '2.5';
		//}

		row = {
			log_type:    'entry',
			date:        logRow[1],
			rotation:    rotation,
			serving:     serving,
			player_team: logRow[4],
			player_num:  logRow[5],
			player_name: logRow[6],
			action:      logRow[7],
			code:        logRow[8],
			point:       logRow[9],
			points:      logRow[10],
			server:      logRow[11],
			setter:      logRow[12],
			asstnum:     logRow[13],
			asstname:    logRow[14],
			lineup:      logRow[15],
			detail:      logRow[16],
			rot_serv:    rotation + ':' + serving
		};

	} else if (logRow[0] === 'LogPartial') {

		row = {
			log_type:    'partialEntry',
			date:        logRow[1],
			rotation:    logRow[2],
			serving:     logRow[3],
			player_team: logRow[4],
			player_num:  logRow[5],
			player_name: logRow[6],
			action:      logRow[7],
			code:        logRow[8],
			point:       logRow[9],
			points:      logRow[10],
			server:      logRow[11],
			setter:      logRow[12],
			asstnum:     logRow[13],
			asstname:    logRow[14],
			lineup:      logRow[15],
			detail:      logRow[16],
			rot_serv:    logRow[2] + ':' + logRow[3]
		};
	}

	return row;
}

