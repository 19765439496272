
export { App };

import 'jquery-templates';

import { Utils } from '../lib/utils.js';
import { CONFIG } from './config.js';
import { wrGraph } from './graphs.js';
import { Stats } from './stats.js';
import { StatPrep } from './stat_prep.js';
import { init as initReportUtils, setResizeHandler } from './report_utils.js';
import { init as initPointTree } from './point_tree.js';

var App = (function () {

	var NUMBER_OF_TABS        = 39;
	var DISPLAY_TAB_TIMEOUT   = CONFIG.DISPLAY_TAB_TIMEOUT;
	var DISPLAY_TAB_THRESHOLD = CONFIG.DISPLAY_TAB_THRESHOLD;

	// references to modules
	var webReport;
	var editData;
	var Api;
	var Analytics;
	var Columns;
	var ReportConfig;
	var DocsExport;

	var debugMode = false;
	var onLeaveTab = null;
	var currentTab;
	var routeInfo = {};
	var loadingData = false;

	var values = {
		'isPro': false,
		'isDemo': false,
		'app': 'web_reports'
	};

	var path = window.location.pathname.split("/");
	var soloUid = path[3];

	let isLiveStat = false;
	let isOrg = false;

	if (path.length === 8) {
		isLiveStat = true;
	} else if (path.length === 5) {
		isOrg = true;
	}

	var selectTab = function (id, opt) {

		if ($('.block-tab-change').get().length){
			// We don't allow tab changes while a Google Doc export is in progress.
			// May be used for other similar situations.
			return false;
		}

		id = +id;

		// handle leaving a tab
		if ((currentTab != id) && (typeof onLeaveTab === "function")) {
			onLeaveTab()
				.then(() => {
					onLeaveTab = null;
					_selectTab(id, opt);
				});
		} else {
			_selectTab(id, opt);
		}

	};

	var _selectTab = (id, opt) => {

		// function for maintaining "Other Reports" functionality
		var setOtherReportsActive = function () {
			$("#SOLO_tab13").show();
			$("#SOLO_tab_submenu_13").show();
			$("#SOLO_tab_marker_13").addClass("active");
		};
		var setBetaReportsActive = function () {
			$("#SOLO_tab36").show();
			$("#SOLO_tab_submenu_36").show();
			$("#SOLO_tab_marker_36").addClass("active");
		};

		// Default is to show team specific information
		$("#team-nav-header").show();

		currentTab = id;

		for (var i=1; i <= NUMBER_OF_TABS; i++) {
			if (i == id) {
				continue;
			}
			$("#SOLO_tab" + i).hide();
			$("#SOLO_tab_submenu_" + i).hide();
			$("#SOLO_tab_marker_" + i).removeClass("active");
			webReport.hideAllNotifications();
		}

		$("#SOLO_tab" + id).show();
		$("#SOLO_tab_submenu_" + id).show();
		$("#SOLO_tab_marker_" + id).addClass("active");
		// FIXME: check to see if matches have changed before re-processing

		// This needs to go before the tab handling, as some contain redirects
		if (typeof Analytics !== "undefined"){
			var gaPageName = Analytics.getPageNameForTab(id, isDemo(), opt);
			Utils.sendAnalyticsPage(gaPageName);
		}

		switch (id) {
			case 1:
				$("#team-nav-header").hide();
				break;
			case 2:
				onLeaveTab = function ()  {
					$("#hitdig_import").hide();
					$(".information").hide();
					return $.when();
				};
				webReport.setMatchAndGameSelectByClickingFields();
				break;
			case 3:
				// check to see if we need to process again
				webReport.processSummaryStats();
				break;
			case 4:
				setOtherReportsActive();
				webReport.processRotationEefStats();
				break;
			case 5:
				setOtherReportsActive();
				webReport.processPlayerEefStats();
				break;
			case 6:
				setOtherReportsActive();
				webReport.processMatchAggregateStats();
				webReport.processGameAggregateStats();
				break;
			case 8:
				setOtherReportsActive();
				webReport.processTrendStats();
				break;
			case 9:
				setOtherReportsActive();
				webReport.processRotationByPlayerStats();
				break;
			case 10:
				$("#team-nav-header").hide();
				// if we're in demo, nothing will be saved
				if (!isDemo()) {
					var settingsChanged = false;

					// if anything in settings is changed, we want to save
					$("#SOLO_tab10").on("change", ":checkbox", function() {
						settingsChanged = true;
					});

					$("#SOLO_tab10").on("change", ":radio", function() {
						settingsChanged = true;
					});

					onLeaveTab = function ()  {
						let deferred = new $.Deferred();
						if (settingsChanged) {
							return webReport.saveSettings()
								.then(() => {
									if ($("#SOLO_settings_select").data("level") === "team") {
										webReport.setMatchSelectForTeam(webReport.currentTeam);
									}
								});
						}
						$("#SOLO_tab10").off("change");

						deferred.resolve();
						return deferred.promise();
					};
				}

				break;
			case 11:
				webReport.processAnalyze();
				break;
			case 12:
				webReport.processPlayerRanking();
				break;
			case 13:
				break;
			case 14:
				setOtherReportsActive();
				if (opt === 'match') {
					webReport.processPlayerByMatchStats();
					$("#SOLO_tab_submenu_14 .by_game").removeClass('selected');
					$("#SOLO_tab_submenu_14 .by_match").addClass('selected');
				} else {

					webReport.processPlayerByGameStats();
					$("#SOLO_tab_submenu_14 .by_game").addClass('selected');
					$("#SOLO_tab_submenu_14 .by_match").removeClass('selected');
				}
				break;
			case 15:
				setOtherReportsActive();
				webReport.processPointLogs();
				break;
			case 16:
				setOtherReportsActive();
				webReport.processBoxScoresStats();
				webReport.processBoxScoresStatsForTeam();
				webReport.processBoxScoresStatsForGame();
				break;
			case 17:
				setOtherReportsActive();
				webReport.processPointTreesStats();
				break;
			case 19:
				setOtherReportsActive();
				window.location.href = 'https://www.rotate123.com/fan';
				break;
			case 20:
				setOtherReportsActive();
				window.location.href = 'https://www.rotate123.com/fan';
				break;
			case 21:
				setOtherReportsActive();
				webReport.processCoachLive(routeInfo.soloId, routeInfo.teamId, routeInfo.matchId, routeInfo.gameNum);
				break;
			case 22:
				setOtherReportsActive();
				webReport.processCoachGameSelect();
				break;
			case 23: // no longer used
				setOtherReportsActive();
				webReport.processCoachLivePlayerByGameStats();
				break;
			case 24:
				setOtherReportsActive();
				webReport.processCoachTeamSelect();
				break;
			case 25:
				webReport.processServicesMenu();
				break;
			case 26:
				setOtherReportsActive();
				webReport.processShareInvites();
				break;
			case 27:
				setOtherReportsActive();
				webReport.processBenchmarks();
				break;
			case 28:
				setOtherReportsActive();
				webReport.processTeamSnap();
				break;
			case 29:
				setOtherReportsActive();
				webReport.processTouchInvites();
				break;
			case 30:
				if (opt && opt.match && opt.game) {
					webReport.processMergeTouch(opt.game);
				}
				break;
			case 32:
				setOtherReportsActive();
				webReport.processAttackBySetter();
				break;
			case 33:
				setOtherReportsActive();
				webReport.processErrorTreesStats();
				break;
			case 34:
				setBetaReportsActive();
				webReport.processSummaryByTournamentStats();
				break;
			case 35:
				setBetaReportsActive();
				webReport.processTournamentComparisonByPlayerStats();
				break;
			case 36:
				break;
			case 37:
				setBetaReportsActive();
				webReport.processSummaryByMatchStats();
				break;
			case 38:
				setBetaReportsActive();
				webReport.processMatchComparisonByPlayerStats();
				break;
			case 39:
				DocsExport.initGoogleDocsExportTab();
				break;
		}

		webReport.currentTab = id;

		if (isRouting()) {
			updateRoutePath(id);
		}
	};

	var prepJqueryTmplOptions = function () {

		(function ($) {
			$.extend(jQuery.tmpl.tag, {
				"for": {
					_default: {$2: "var i=1;i<=1;i++"},
					open: 'for ($2){',
					close: '};'
				}
			});
		})(jQuery);
	};

	var calculateProcessingTimeForTab = function(tab) {
		var processingTime = 0;
		var NUMBER_OF_ROTATIONS = 6;

		var games = webReport.getSelectedGamesAndMatches().games;
		var players = webReport.getPlayerNumsByTeam(webReport.currentTeam);
		var matches = webReport.getSelectedGamesAndMatches().matches;

		switch (tab) {
			case 3: //Summary tab
				// player report (games)
				processingTime += games.length;
				// game report (games)
				processingTime += games.length;
				// rotation I report (games)
				processingTime += games.length;
				// rotation II report (games)
				processingTime += games.length;
				break;

			case 4: //Rotation Tab
				//Rotation Serving
				processingTime += games.length;
				//Rotation Receiving
				processingTime += games.length;
				break;

			case 6: //Skills By Opponent Tab
				//Match Aggregate
				processingTime += games.length;
				//Game Aggregate
				processingTime += games.length;
				break;

			case 9: //Rotation By Player Tab
				//Serving
				// Adding a constant to reflect realtime observations
				processingTime += games.length * NUMBER_OF_ROTATIONS * 3;
				//Receiving
				processingTime += games.length * NUMBER_OF_ROTATIONS * 3;
				break;

			case 11: //Analyze Tab
				//Serving
				processingTime += games.length;
				//Receiving
				processingTime += games.length;
				break;

			case 12: //Player Ranking Tab
				processingTime += games.length;
				break;

			case 14: //Player By Game Tab
				// Adding a constant to reflect realtime observations
				processingTime += games.length * players.length * 2;
				break;

			case 15: //Point Logs Tab
				// Adding a constant to reflect realtime observations
				processingTime += games.length * 10;
				break;

			case 16: //Box Scores Tab
				//Box Scores
				processingTime += games.length;
				//Box Scores Game
				processingTime += games.length;
				//Box Scores Team
				processingTime += matches.length;
				// Adding a constant to reflect realtime observations
				processingTime *= 2;
				break;

			case 17: //Point Trees Tab
				processingTime += games.length;
				break;

			default: processingTime = 0;
				break;
		}

		return processingTime;
	};

	var bindTabHandlers = function () {

		var createHandler = function (tab) {
			return function () {
				if (tab != 13 && tab != 36) {
					webReport.currentTab = tab;
					var processingTime = calculateProcessingTimeForTab(tab);

					if (processingTime < DISPLAY_TAB_THRESHOLD) {
						selectTab(tab);
					} else {
						webReport.disableViewport();

						var promise = (function () {
							var deferred = new $.Deferred();

							setTimeout(function() {
								selectTab(tab);
								deferred.resolve();
							}, DISPLAY_TAB_TIMEOUT);
							return deferred.promise();
						})();

						promise.always(function () {
							webReport.enableViewport();
						});
					}
				}
			};
		};

		var i;
		for (i=1; i <= NUMBER_OF_TABS; i++) {
			$("#SOLO_tab_marker_" + i).on("click", createHandler(i));
		}

		// Special sub menu handlers
		$("#SOLO_tab_marker_14_sub_game").on("click", function () {
			var games = webReport.getSelectedGamesAndMatches().games;
			var players = webReport.getPlayerNumsByTeam(webReport.currentTeam);

			var processingTime = games.length * players.length;

			if (processingTime > DISPLAY_TAB_THRESHOLD) {
				webReport.disableViewport();

				var promise = (function () {
					var deferred = new $.Deferred();

					setTimeout(function() {
						selectTab(14, "game");
						deferred.resolve();
					}, DISPLAY_TAB_TIMEOUT);
					return deferred.promise();
				})();

				promise.always(function () {
					webReport.enableViewport();
				});
			} else {
				selectTab(14, "game");
			}
		});

		$("#SOLO_tab_marker_14_sub_match").on("click", function () {
			var matches = webReport.getSelectedGamesAndMatches().matches;
			var players = webReport.getPlayerNumsByTeam(webReport.currentTeam);

			var processingTime = matches.length * players.length;

			if (processingTime > DISPLAY_TAB_THRESHOLD) {
				webReport.disableViewport();

				var promise = (function () {
					var deferred = new $.Deferred();

					setTimeout(function() {
						selectTab(14, "match");
						deferred.resolve();
					}, DISPLAY_TAB_TIMEOUT);
					return deferred.promise();
				})();

				promise.always(function () {
					webReport.enableViewport();
				});
			} else {
				selectTab(14, "match");
			}
		});
	};

	var init = function (opts) {

		if (opts && opts.touch) {
			values.app = 'touch';
		}

		webReport = opts.webReport;
		editData = opts.editData;
		Api = opts.Api;
		Analytics = opts.Analytics;
		Columns = opts.Columns;
		ReportConfig = opts.ReportConfig;
		DocsExport = opts.DocsExport;

		// This is set by an optional "debug" param in the query string
		// i.e. /solo/reports/.../?debug=true
		debugMode = Utils.getParameterByName('debug');

		// TODO: What was this for?
		window.width = $(document).width() - (0.035 * $(document).width());

		setResizeHandler();

		Api.init(soloUid);
		Stats.init({ webReport: webReport });
		StatPrep.init({ touch: isTouch(), webReport: webReport, editData: editData });
		initReportUtils({ webReport: webReport, Columns: Columns, ReportConfig: ReportConfig });
		initPointTree({ webReport: webReport });
	};

	var start = function () {

		var xinvite      = {};
		var xteam        = {};
		var xmatch       = {};
		var xgame        = {};
		var xmatch_array = [];
		var xgame_array  = [];
		var tsAuthUrl;
		var tsAuthOk;
		var tsActiveTeams;
		var tsTeamData;
		var hiddenTeams;
		var hiddenMatches;
		var hiddenGames;
		var rotNumConv;


		Api.fetchInitValues()
			.then(function (rsp) {
				hiddenTeams = rsp.hidden_teams;
				hiddenMatches = rsp.hidden_matches;
				hiddenGames = rsp.hidden_games;
				tsAuthUrl = rsp.ts_auth_url;
				tsAuthOk = rsp.ts_auth_ok;
				tsActiveTeams = rsp.ts_active_teams;
				tsTeamData = rsp.ts_team_data;
				rotNumConv = rsp.rot_num_conv;
				values.isPro = rsp.is_pro;
				values.isDemo = rsp.is_demo;

				if (isDemo()) {
					$("#settings-all-teams").hide();
					$(".settings-team").hide();
					$("#manage-tournaments").hide();
				}

			})
			.then(function () {
				bindTabHandlers();
				prepJqueryTmplOptions();

				// set the version triggered release notes
				$("#SOLO_tabs_header .info_icon").click(function () {
					var infoId = $(this).attr('data-id');
					// close all other information windows
					$('.information').each(function () {
						var infoIdIteration = $(this).attr('id');
						if (infoIdIteration != infoId) {
							$(this).hide();
						}
					});
					$('#' + infoId).toggle();
				});
			})
			.then(function () {
				$("#SOLO_settings_rot_num_conv input[type=radio]").each(function () {
					if ($(this).val() === rotNumConv) {
						$(this).attr('checked', 'checked');
					}
				});
			})
			.then(Api.prepareSoloData)
			.then(function (rsp) {
				xinvite      = rsp.xinvite;
				xteam        = rsp.xteam;
				xmatch       = rsp.xmatch;
				xgame        = rsp.xgame;
				xmatch_array = rsp.xmatch_array;
				xgame_array  = rsp.xgame_array;
			})
			.then(function () {
				webReport.init({
					soloUid: soloUid,
					xinvite: xinvite,
					xteam: xteam,
					xmatch: xmatch,
					xgame: xgame,
					xmatch_array: xmatch_array,
					xgame_array: xgame_array,
					tsAuthUrl: tsAuthUrl,
					tsAuthOk: tsAuthOk,
					tsActiveTeams: tsActiveTeams,
					tsTeamData: tsTeamData,
					hiddenTeams: hiddenTeams,
					hiddenMatches: hiddenMatches,
					hiddenGames: hiddenGames
				});
				webReport.initLayout();

				if (isOrg) {
					$("#SOLO_tab_marker_1").hide();
				}
			})
			.then(route)
			.then(function () {
				wrGraph.init({ webReport: webReport, Columns: Columns, ReportConfig: ReportConfig });
			})
			.catch(function (rsp) {
				let errorCode = 'initialization-error';
				let errorDetail = '';
				if (rsp.responseJSON && rsp.responseJSON.code) {
					errorCode = rsp.responseJSON.code;
					errorDetail = rsp.responseJSON.detail || "";
				}

				let contactUs = "<a href='https://www.solostatslive.com/contact-us.html' target='_blank'>contact us</a>";
				let errorMsg = '';
				let redirect = '';
				switch (errorCode) {
					case 'subscription-expired':
						errorMsg = `<p>We're sorry, but it looks like your subscription has expired.  To extend your service, <a href="/subscriptions">click here</a> to manage your subscriptions. If you have any questions regarding your subscription, please ${contactUs}.`;
						break;

					case 'webreports-only-subscription':
						errorMsg = `<p>We're sorry, but your SoloStats 123 &amp; LIVE Starter Bundle subscription does not allow access to Touch stats. Please subscribe to the Intermediate Bundle to access both Touch and Match Stats (SoloStats123 or Live). <a href="/subscriptions">Click here</a> to manage your subscriptions.</p>`;
						break;

					case 'touch-only-subscription':
						errorMsg = `<p>We're sorry, but your Touch subscription does not allow access to WebReports Match stats. Please subscribe to the Intermediate Bundle to access both Touch and Match Stats (SoloStats123 or Live). <a href="/subscriptions">Click here</a> to manage your subscriptions.</p>`;
						break;

					case 'no-subscription':
						errorMsg = `<p>You need an active subscription to be able to access this content. <a href="/subscriptions">Click here</a> to manage your subscriptions.</p>`;
						break;

					case 'permission-denied':
						redirect = `${window.location.origin}/member/login?redirect=` + encodeURIComponent(window.location.pathname + window.location.search);
						errorMsg = `<p>Sorry, the requested data does not exist, or you do not have permission to view it. If you have multiple accounts you may be logged into the wrong one. <a href="${redirect}">Click here</a> to try logging in again.</p>`;
						break;

					default:
						errorMsg = `<p>Sorry, there was an error loading your data. Try re-loading the page. If the problem persists, please <a href='https://www.solostatslive.com/contact-us.html' target='_blank'>contact us</a>. Copy and paste the error details below to help us better assist you.</p><textarea rows='1' cols='80' readonly>{${errorCode}} ${errorDetail}</textarea>`;
				}

				$("#SOLO_initialization .loading").hide();
				$("#SOLO_initialization").find(".message").html(errorMsg);
				$("#SOLO_initialization .error").show();
			});

	};

	// This is currently only in an alpha state, and is only intended to be
	// used to test live stat viewing
	var route = function () {

		if (!isLiveStat && !isOrg) {
			$("#SOLO_tabs").show();
			$("#footer").show();
			selectTab(1);
			return;
		}

		// find team
		var soloId  = path[3];
		var teamId  = path[4];
		let matchId, gameNum, tabId;

		if (isLiveStat) {
			matchId = path[5];
			gameNum = path[6];
			tabId   = path[7];
		}

		if (isOrg) {
			// team match select page
			tabId = 2;
			matchId = 0; // for loadDataForTeam()
		}

		if (tabId < 19) {
			$("#SOLO_tabs").show();
			$("#footer").show();
		}

		routeInfo = {
			routing : true,
			soloId  : soloId,
			teamId  : teamId,
			matchId : matchId,
			gameNum : gameNum,
			tabId   : tabId
		};

		// only pull data and poll for updates if page displays live data
		if (tabId == "25") { // Tab 25 - services menu (Reports/Live Broadcast)
			selectTab(tabId);
			return;
		}

		webReport.selectTeam(teamId);

		// load team data if no loading is in progress
		if (loadingData === false) {
			loadingData = true; // set loading in progress

			Api.loadDataForTeam(teamId, matchId).then(() => {

				if (webReport.get_xmatch_array().length > 0 && tabId < 19) { // make sure have received data
					if (teamId != "0") {
						if (matchId) {
							webReport.setMatchSelectForTeam(teamId);
						}
						webReport.setSelectedTeamInDropdown(teamId);
					}
					if (matchId) {
						if (gameNum) {
							webReport.selectGamesAndMatches(matchId, gameNum);
						}
					} else {
						let matches = webReport.getTeamMatches(teamId, true);
						if (matches.length > 0) {
							webReport.setMatchSelectForTeam(teamId);
						} else {
							webReport.selectTeam(-1);
							// TODO: display error message
						}
					}
				}

				loadingData = false; // set loading complete

				// redirect to tab
				selectTab(tabId);
			});
		}

	};

	var isRouting = function () {
		return !!routeInfo.routing;
	};

	var updateRoutePath = function (tabId) {

		// The only thing we'll let users change for the time being is
		// the report

		// if the tabId is the same, do nothing
		if (+tabId == +routeInfo.tabId) {
			return;
		}

		if (isOrg) {
			// don't need to update for Org
			return;
		}

		var url = "/" + path[1] + "/" + path[2] + "/" + path[3];

		url += "/" + routeInfo.teamId;
		url += "/" + routeInfo.matchId;
		url += "/" + routeInfo.gameNum;
		url += "/" + tabId;

		if (window.location.search) {
			url += window.location.search;
		}

		window.onpopstate = function() {
			route();
		};

		window.history.pushState({}, "", url);

	};

	var isMobileDevice = function () {
		return CONFIG.IS_MOBILE;
	};

	var isPro = function () {
		return values.isPro;
	};

	var isDemo = function () {
		return values.isDemo;
	};

	var isTouch = function () {
		return values.app === 'touch' ? true : false;
	};

	var getSoloUid = function () {
		return soloUid;
	};

	var debugLog = function(data) {
		if (debugMode) {
			//console.log(data);
			console.log('%c ' + data, 'background: #222; color: #bada55');
		}
	};

	return {
		init                          : init,
		start                         : start,
		isMobileDevice                : isMobileDevice,
		isPro                         : isPro,
		isDemo                        : isDemo,
		isTouch                       : isTouch,
		getSoloUid                    : getSoloUid,
		debugLog                      : debugLog,
		calculateProcessingTimeForTab : calculateProcessingTimeForTab,
		selectTab                     : selectTab
	};

})();
