
export {
	get_point_tree_opponent_hit_errors,
	get_point_tree_kills,
	get_point_tree_opponent_faults,
	get_point_tree_aces,
	get_point_tree_blocks,
	get_point_tree_opponent_fbr_errors,
	generate_all_point_trees,
	generate_all_error_trees,
	init,
};

import { StatPrep } from './stat_prep.js';
import { Stats } from './stats.js';

// since we can't dynamically import from web_reports OR touch_reports,
// we'll need these to be passed in.
let webReport;
var init = function (opts) {
	if (opts && opts.webReport) {
		webReport = opts.webReport;
	}
};

var get_point_tree_opponent_hit_errors = function (stats, who) {
	var not = (who === "us") ? "them" : "us";
	var block_details = stats[not].attack_error_detail_blocked;
	var hit_errors = stats[not].combined_kill_errors - block_details;

	return hit_errors;
};

var get_point_tree_kills = function (stats, who) {
	var not = (who === "us") ? "them" : "us";

	var kills =
		stats[who].combined_kills +
		// Stats already adds these into combined kills.  Should I add digs and
		// FBP to Stats?
		// stats[not].errors_blck +
		// stats[not].errors_dig +
		stats[not].errors_whb;

	return kills;
};

var get_point_tree_opponent_faults = function (stats, who) {
	var not = (who === "us") ? "them" : "us";

	var faults =
		stats[not].faults_cnt +
		stats[not].errors_set -
		stats[not].faults_bra -
		stats[not].faults_foot;  // these are counted in serve errors

	return faults;
};

var get_point_tree_aces = function (stats, who) {
	var aces = stats[who].aces;
	return aces;
};

var get_point_tree_blocks = function (stats, who) {
	//var not = (who === "us") ? "them" : "us";

	var blocks;
	if (who === "us") {
		blocks = stats.us.block_solos + (stats.us.block_assists / 2) + stats.them.attack_error_detail_blocked;
	} else {
		blocks = stats.them.earned_blck;
	}

	return blocks;
};

var get_point_tree_opponent_fbr_errors = function (stats, who) {
	var not = (who === "us") ? "them" : "us";

	var errors =
		stats[not].errors_fbp +
		stats[not].errors_2br +
		stats[not].errors_3br;

	return errors;
};

var generate_point_tree = function (target, columns, header, stats_us, stats_them) {

	var stats = { "us": stats_us, "them": stats_them };

	var template_values = {
		'header'                  : header,
		'us_score'                : stats_us.points_for,
		'them_score'              : stats_us.points_against,
		'us_opp_serve_errors'     : stats_them.serve_errors,
		'them_opp_serve_errors'   : stats_us.serve_errors,
		'us_opp_hit_errors'       : get_point_tree_opponent_hit_errors(stats, "us"),
		'them_opp_hit_errors'     : get_point_tree_opponent_hit_errors(stats, "them"),
		'us_kills'                : get_point_tree_kills(stats, "us"),
		'them_kills'              : get_point_tree_kills(stats, "them"),
		'us_opp_faults'           : get_point_tree_opponent_faults(stats, "us"),
		'them_opp_faults'         : get_point_tree_opponent_faults(stats, "them"),
		'us_aces'                 : get_point_tree_aces(stats, "us"),
		'them_aces'               : get_point_tree_aces(stats, "them"),
		'us_blocks'               : get_point_tree_blocks(stats, "us"),
		'them_blocks'             : get_point_tree_blocks(stats, "them"),
		'us_opp_fbr_errors'       : get_point_tree_opponent_fbr_errors(stats, "us"),
		'them_opp_fbr_errors'     : get_point_tree_opponent_fbr_errors(stats, "them"),
		'us_pass_rating'          : stats_us.pass_rating,
		'them_pass_rating'        : stats_them.pass_rating,
		'us_point_score_percent'  : stats_us.pt_score_percent,
		'them_point_score_percent': stats_them.pt_score_percent,
		'us_side_out_percent'     : stats_us.side_out_percent,
		'them_side_out_percent'   : stats_them.side_out_percent,
		'us_adjustment'           : stats_us.us_adjustment,
		'them_adjustment'         : stats_them.them_adjustment
	};

	var template = $('#' + 'template_point_tree').html();
	var template_filled = $.tmpl(template, template_values);

	$(target).append(template_filled);
};

var generate_all_point_trees = function (match_id) {

	var gameMatch = webReport.getGamesByMatch(match_id);

	var divMatchId = 'SOLO_point_tree_' + match_id;
	$('#'+divMatchId).empty();
	$('#'+divMatchId).after($("<br>"));
	$('<div/>', {
		id: divMatchId
	}).appendTo('#SOLO_point_tree_wrapper');

	$('#'+divMatchId).append("<h2>" + webReport.get_xmatch()[match_id].venue+", " + webReport.get_xmatch()[match_id].opponent + "</h2>");
	$('#'+divMatchId).css('clear', 'both');

	var result, stats1, stats2;

	var columns = [
		'points_for',
		'points_againts',
		'game_id',
		'combined_kills',
		'combined_kill_errors',
		'aces',
		'blocks',
		'earned_blck',
		'serve_errors',
		'pass_rating',
		'pt_score_percent',
		'side_out_percent',
		'us_adjustment',
		'them_adjustment'
	];

	var num_games = gameMatch.length;

	result = StatPrep.processStatsIteration(webReport.data, { type: 'game', list: gameMatch }, null, false);
	var inRallyBoolean = webReport.buildInRallyBoolean(result);

	stats1 = Stats.calc(result.byRow, columns, { blockAssistsHalfValue: true, inRally: inRallyBoolean, who: 'us' });
	stats2 = Stats.calc(result.byRow, columns, { blockAssistsHalfValue: true, inRally: inRallyBoolean, who: 'them' });

	for (let i=0; i < num_games; i++) {
		if (stats1[i] && stats2[i] && typeof stats1[i] !== "undefined" && typeof stats2[i] !== "undefined") {
			let header = 'G' + webReport.get_xgame()[gameMatch[i]].gameNum;
			let pointTreeDivId = 'point_tree_target_' + gameMatch[i];
			$('#'+pointTreeDivId).empty();
			$('<div/>', {
				id: pointTreeDivId,
			}).appendTo('#'+divMatchId);
			generate_point_tree($("#"+pointTreeDivId), columns, header, stats1[i], stats2[i]);
		}
	}

	// Match Stats for Point Tree
	result = StatPrep.processStatsIteration(webReport.data, { type: 'match', list: [match_id] }, null, false);

	stats1 = Stats.calc(result.byRow, columns, { blockAssistsHalfValue: true, inRally: inRallyBoolean, who: 'us' });
	stats2 = Stats.calc(result.byRow, columns, { blockAssistsHalfValue: true, inRally: inRallyBoolean, who: 'them' });

	let pointTreeDivId = 'point_tree_target_' + match_id;
	$('#'+pointTreeDivId).empty();
	$('<div/>', {
		id: pointTreeDivId,
	}).appendTo('#'+divMatchId);
	generate_point_tree($("#"+pointTreeDivId), columns, 'Match', stats1[0], stats2[0]);
};

var generate_error_tree = function (target, columns, header, stats_us, stats_them) {

	var template_values = {
		'header': header,
		'us_total_err': stats_us.errors_cnt + stats_us.faults_cnt,
		// NOTE: stats_them.serve_rcv_errors are not included in stats_them.errors_cnt for unknown reasons. Adding here so them_total_err
		//   adds up correctly, but there may be a better place to do this.
		'them_total_err': stats_them.errors_cnt + stats_them.faults_cnt + stats_us.combined_kills + stats_them.serve_rcv_errors,
		'us_attack_err': stats_us.combined_kill_errors,
		'them_attack_err': stats_them.combined_kill_errors,
		'us_serve_err': stats_us.serve_errors,
		'them_serve_err': stats_them.serve_errors,
		'us_receive_err': stats_us.serve_rcv_errors,
		'them_receive_err': stats_them.serve_rcv_errors,
		'us_dig_err': stats_us.errors_dig,
		'them_dig_err': stats_them.errors_dig + stats_us.combined_kills,
		'us_block_err': stats_us.errors_blck,
		'them_block_err': stats_them.errors_blck,
		'us_fbp_err': stats_us.errors_fbp,
		'them_fbp_err': stats_them.errors_fbp,
		'us_misc_err': stats_us.errors_whb + stats_us.errors_2br + stats_us.errors_3br,
		'them_misc_err': stats_them.errors_whb + stats_them.errors_2br + stats_them.errors_3br,
		'us_faults': stats_us.faults_cnt + stats_us.errors_set - stats_us.faults_bra - stats_us.faults_foot,
		'them_faults': stats_them.faults_cnt + stats_them.errors_set - stats_them.faults_bra - stats_them.faults_foot
	};

	var template = $('#' + 'template_error_tree').html();
	var template_filled = $.tmpl(template, template_values);

	$(target).append(template_filled);
};

var generate_all_error_trees = function (match_id) {

	var gameMatch = webReport.getGamesByMatch(match_id);

	var divMatchId = 'SOLO_error_tree_' + match_id;
	$('#'+divMatchId).empty();
	$('#'+divMatchId).after($("<br>"));
	$('<div/>', {
		id: divMatchId
	}).appendTo('#SOLO_error_tree_wrapper');

	$('#'+divMatchId).append("<h2>" + webReport.get_xmatch()[match_id].venue + ", " + webReport.get_xmatch()[match_id].opponent+"</h2>");
	$('#'+divMatchId).css('clear', 'both');

	var result, stats1, stats2;

	var columns = [
		'errors_cnt',
		'faults_cnt',
		'combined_kill_errors',
		'serve_errors',
		'serve_rcv_errors',
		'errors_dig',
		'combined_kills',
		'errors_blck',
		'errors_fbp',
		'errors_whb',
		'errors_2br',
		'errors_3br',
		'faults_cnt',
		'errors_set',
		'faults_bra',
		'faults_foot'
	];

	var num_games = gameMatch.length;

	result = StatPrep.processStatsIteration(webReport.data, { type: 'game', list: gameMatch }, null, false);
	var inRallyBoolean = webReport.buildInRallyBoolean(result);

	stats1 = Stats.calc(result.byRow, columns, { blockAssistsHalfValue: true, inRally: inRallyBoolean, who: 'us' });
	stats2 = Stats.calc(result.byRow, columns, { blockAssistsHalfValue: true, inRally: inRallyBoolean, who: 'them' });

	for (let i=0; i < num_games; i++) {
		if (stats1[i] && stats2[i] && typeof stats1[i] !== "undefined" && typeof stats2[i] !== "undefined") {
			var header = 'G' + webReport.get_xgame()[gameMatch[i]].gameNum;
			let errorTreeDivId = 'error_tree_target_' + gameMatch[i];
			$('#'+errorTreeDivId).empty();
			$('<div/>', {
				id: errorTreeDivId,
			}).appendTo('#'+divMatchId);
			generate_error_tree($("#"+errorTreeDivId), columns, header, stats1[i], stats2[i]);
		}
	}

	// Match Stats for Error Tree
	result = StatPrep.processStatsIteration(webReport.data, { type: 'match', list: [match_id] }, null, false);

	stats1 = Stats.calc(result.byRow, columns, { blockAssistsHalfValue: true, inRally: inRallyBoolean, who: 'us' });
	stats2 = Stats.calc(result.byRow, columns, { blockAssistsHalfValue: true, inRally: inRallyBoolean, who: 'them' });

	let errorTreeDivId = 'error_tree_target_' + match_id;
	$('#'+errorTreeDivId).empty();
	$('<div/>', {
		id: errorTreeDivId,
	}).appendTo('#'+divMatchId);
	generate_error_tree($("#"+errorTreeDivId), columns, 'Match', stats1[0], stats2[0]);
};
