
export { CONFIG };

import { Utils } from '../lib/utils.js';

var CONFIG = (function () {

	var data = {
		ROWS_PER_GRID_PAGE_MOBILE : 15,
		STAT_NOT_AVAILABLE        : "-",
		STAT_INVALID              : "???",
		HEADER_COLOR_NON          : "#9933ff",
		HEADER_COLOR_PLUS         : "#0066ff",
		HEADER_COLOR_MINUS        : "#ff66ff",
		HEADER_COLOR_RALLY        : "#777",
		DISPLAY_TAB_TIMEOUT       : 100,
		TEAM_STAT_ID              : "team", // player IDs are always strings
		TEAM_STAT_NAME            : "(team)",
		HILITE_ONE_STD_DEV        : "#CCFFFF",
		HILITE_TWO_STD_DEV        : "#00ffff",
		HAS_EDITABLE_STATS        : true,
		PRODUCT                   : "web_reports",

		ERROR_500: 'Sorry, there was a system error, please try again.  If this problem persists, please contact support@rotate123.com',
		ERROR_SAVE_500: 'Sorry, there was a system error, and your changes were not saved.  Please try again.  If this problem persists, please contact support@rotate123.com',
		ERROR_CONNECTION_SAVE: 'Sorry, there was a problem saving your changes.  Please check your network connection and try again.'
	};

	// Is this a mobile device? The "mobile" variable allows us to manually
	// set our preferred environment
	var optMobile = Utils.getParameterByName('mobile');
	if (optMobile === "true") {
		data.IS_MOBILE = true;
	} else if (optMobile === "false") {
		data.IS_MOBILE = false;
	} else if (Utils.isMobileDevice()) {
		data.IS_MOBILE = true;
	} else {
		data.IS_MOBILE = false;
	}

	if (data.IS_MOBILE) {
		data.DISPLAY_TAB_THRESHOLD = 150;
	} else {
		data.DISPLAY_TAB_THRESHOLD = 200;
	}

	return data;

})();

